class ScrollArrow {
  constructor() {
    let scrollArrow = document.getElementById('scroll-arrow-button');
    scrollArrow.addEventListener('click', scrollToTop);

    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.activeElement.blur();
    }
  }
}

export default ScrollArrow;
