import * as PIXI from 'pixi.js';
import gsap from "gsap";
import { TweenMax, Power4 }  from "gsap/all";
import Physics2D from '../utilities/Physics2DPlugin.min';
import each from 'lodash/each';
import random from 'lodash/random';
import uniqueId from 'lodash/uniqueId';
import pull from 'lodash/pull';

const colors = [
  0xF9B00B,
  0xE82352,
  0x0EB8AC,
  0xA9DFAD,
  0x24235D,
  0xF7785A
];

const WIDTH = 1500;
const HEIGHT = 1400;
const HALF_WIDTH = WIDTH / 2;
const HALF_HEIGHT = HEIGHT / 2;
// const COUNT = 150;
const COUNT = 75;
const SIZE = 20;

const skew = [0.02, 0.04, 0.06, 0.08, 0.1];
const xPlus = [0.05*5, 0.1*5, 0.15*5, 0.2*5, 0.25*5];

// some constants
const DECAY = 4;        // confetti decay in seconds
const SPREAD = 60;      // degrees to spread from the angle of the cannon
const GRAVITY = 1200;

// utilities
function getLength(x0, y0, x1, y1) {
  // returns the length of a line segment
  const x = x1 - x0;
  const y = y1 - y0;
  return Math.sqrt(x * x + y * y);
}

function getDegAngle(x0, y0, x1, y1) {
  const y = y1 - y0;
  const x = x1 - x0;
  return Math.atan2(y, x) * (180 / Math.PI);
}

let windowIsFocused = true;

class Confetti {
  constructor( renderer, container, origin ) {

    window.addEventListener("focus", function(event) {
      // console.log('focus');
      windowIsFocused = true;
    });

    window.addEventListener("blur", function(event) {
      // console.log('blur');
      windowIsFocused = false;
    });

    gsap.registerPlugin(Physics2D);

    let confetti = this;
    confetti.active = false;
    confetti.origin = origin;

    confetti.container = container;
    confetti.container.alpha = 0;

    let graphics = new PIXI.Graphics();

    // generate textures for confetti
    let textures = [];
    each(colors, c => {
      graphics.clear();
      graphics.beginFill(c, 1);
      graphics.drawRect(0, 0, SIZE, SIZE);
      graphics.endFill();
      let texture = renderer.generateTexture(graphics);
      textures.push(texture);
    });
    confetti.textures = textures;
    let ambient = [];
    for (let i = 0; i < COUNT; i++) {
      let colorIndex = i % colors.length;
      let x = random( -HALF_WIDTH, HALF_WIDTH );
      let y = random( -HALF_HEIGHT, HALF_HEIGHT );
      let s = PIXI.Sprite.from(textures[colorIndex]);
      let scale = random(0.25, 1);
      s.xAdd = i % 2 ? 1 : -1;
      s.anchor.set(0.5);
      s.x = x;
      s.y = y;
      s.scale.x = scale;
      s.scale.y = scale;
      s.skew.y = i % 10 * 0.1;
      s.rotation = 0.1 + ((i % 5) * 0.1);
      container.addChild(s);
      ambient.push(s);
    }

    this.confettiSpriteIds = [];
    this.confettiSprites = {};
    this.cannonSprites = [];

    // vector line representing the firing angle
    this.drawVector = false;
    this.vector = [{
      x: origin.x,
      y: origin.y,
    }, {
      x: origin.x,
      y: origin.y + 500,
    }];
    this.pointer = {};
    // this.timer = setTimeout(() => {
    //   this.handleMouseup();
    // }, 1000);

    confetti.entities = {
      ambient
    };

  }
  handleMouseup () {
    this.drawVector = false;

    const x0 = this.vector[0].x;
    const y0 = this.vector[0].y;
    const x1 = this.vector[1].x;
    const y1 = this.vector[1].y;

    const length = getLength(x0, y0, x1, y1);
    const angle = getDegAngle(x0, y0, x1, y1) + 180;

    const particles = length / 5 + 5;
    const velocity = length * 10;

    this.addConfettiParticles(particles, angle, velocity, x0, y0);
  }
  addConfettiParticles(amount, angle, velocity, x, y) {
    let i = 0;
    while (i < amount) {
      // sprite
      const r = random(4, 6);
      const d = random(15, 25);

      const cr = random(30, 255);
      const cg = random(30, 230);
      const cb = random(30, 230);
      const color = `rgb(${cr}, ${cg}, ${cb})`;

      const tilt = random(10, -10);
      const tiltAngleIncremental = random(0.07, 0.05);
      const tiltAngle = 0;

      let colorIndex = i % colors.length;
      let s = PIXI.Sprite.from(this.textures[colorIndex]);
      s.anchor.set(0.5);
      s.scale.set(random(0.25, 1));
      this.container.addChild(s);

      const id = uniqueId();
      const sprite = {
        [id]: {
          angle,
          velocity,
          x,
          y,
          r,
          d,
          color,
          tilt,
          tiltAngleIncremental,
          tiltAngle,
          s
        },
      };

      Object.assign(this.confettiSprites, sprite);
      this.confettiSpriteIds.push(id);
      this.tweenConfettiParticle(id);
      i++;
    }
  }
  tweenConfettiParticle(id) {
    const minAngle = this.confettiSprites[id].angle - SPREAD / 2;
    const maxAngle = this.confettiSprites[id].angle + SPREAD / 2;

    const minVelocity = this.confettiSprites[id].velocity / 4;
    const maxVelocity = this.confettiSprites[id].velocity;

    // Physics Props
    const velocity = random(minVelocity, maxVelocity);
    const angle = random(minAngle, maxAngle);
    const gravity = GRAVITY;
    const friction = random(0.1, 0.25);
    const d = 0;

    TweenMax.to(this.confettiSprites[id], DECAY, {
      physics2D: {
        velocity,
        angle,
        gravity,
        friction,
      },
      // d,
      ease: Power4.easeIn,
      onComplete: () => {
        // remove confetti sprite and id
        pull(this.confettiSpriteIds, id);
        this.confettiSprites[id].s.destroy();
        delete this.confettiSprites[id];
      },
    });
    TweenMax.to(this.confettiSprites[id].s.scale, DECAY / 4, {
      x: 0,
      y: 0,
      ease: Power4.easeOut,
      delay: DECAY / 4 * 3
    });
  }
  updateConfettiParticle(id) {
    const sprite = this.confettiSprites[id];

    const tiltAngle = 0.0005 * sprite.d;

    sprite.angle += 0.01;
    sprite.tiltAngle += tiltAngle;
    sprite.tiltAngle += sprite.tiltAngleIncremental;
    sprite.tilt = (Math.sin(sprite.tiltAngle - (sprite.r / 2))) * sprite.r * 2;
    sprite.y += Math.sin(sprite.angle + sprite.r / 2) * 2;
    sprite.x += Math.cos(sprite.angle) / 2;
  }
  drawConfetti() {
    this.confettiSpriteIds.map(id => {
      const sprite = this.confettiSprites[id];

      // this.ctx.beginPath();
      // this.ctx.lineWidth = sprite.d / 2;
      // this.ctx.strokeStyle = sprite.color;
      // this.ctx.moveTo(sprite.x + sprite.tilt + sprite.r, sprite.y);
      // this.ctx.lineTo(sprite.x + sprite.tilt, sprite.y + sprite.tilt + sprite.r);
      // this.ctx.stroke();

      let s = sprite.s;

      s.x = sprite.x + sprite.tilt + sprite.r;
      s.y = sprite.y;
      s.angle = sprite.angle;
      s.skew.y = sprite.tiltAngle;

      this.updateConfettiParticle(id);
    });
  }
  start () {
    let confetti = this;
    let origin = confetti.origin;
    confetti.active = true;
    TweenMax.to(confetti.container, 0.1, { alpha: 1 });

    this.vector[1].x = origin.x + random(-250, 250);
    this.handleMouseup();

    this.timer = setInterval(() => {
      if (this.active && windowIsFocused) {
        this.vector[1].x = origin.x + random(-250, 250);
        this.handleMouseup();
        // console.log(this.container.children.length);
      }
    }, 2000);

  }
  stop () {
    let confetti = this;
    if (!confetti.active) {
      return;
    }
    TweenMax.to(confetti.container, 0.5, { alpha: 0, onComplete: () => {
        confetti.active = false;
    } });

    clearInterval(this.timer);
  }
  update (delta) {
    let confetti = this;
    let {
      ambient
    } = confetti.entities;

    if (!confetti.active) {
      return;
    }

    each(ambient, (s, i) => {
      let x = s.x, y = s.y;
      if(s.y > HALF_HEIGHT) {
        x = random( -HALF_WIDTH, HALF_WIDTH );
        y = -HALF_HEIGHT;
      } else {
        y = s.y + 0.2 + ((i % 5) * 0.1);
      }
      if (s.x > HALF_WIDTH) {
        s.xAdd = -1;
      } else if (s.x < -HALF_WIDTH) {
        s.xAdd = 1;
      }
      if(s.xAdd === -1) {
        x = s.x - xPlus[i % 5];
      } else {
        x = s.x + xPlus[i % 5];
      }
      s.y = y;
      s.x = x;
      s.skew.y += i % 2 === 0 ? skew[i % 5] : skew[i % 5] * -1;
      s.rotation += i % 2 === 0 ? (0.01 + ((i % 5) * 0.01)) : (0.01 + ((i % 5) * -0.01));
    });

    this.drawConfetti();

  }
}

export default Confetti;
