/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {Function} func to be executed on debounce
 * @param {number} wait time in milliseconds
 * @param {boolean} immediate boolean
 *
 * */
export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * querySelector wrapper
 *
 * @param {string} selector Selector to query
 * @param {Element} [scope] Optional scope element for the selector
 */
export function qs(selector, scope) {
  return (scope || document).querySelector(selector);
}

/**
 * querySelector All wrapper
 *
 * @param {string} selector Selector to query
 * @param {Element} [scope] Optional scope element for the selector
 */
export function qsArray (selector, scope) {
  var nodeList = (scope || document).querySelectorAll(selector);
  var arr = [];
  for(var i = 0; i < nodeList.length; i++) {
    arr.push(nodeList[i]);
  }
  return arr;
}

/**
 * Degrees to Radians conversion
 *
 * @param {number} degrees
 * @return {number} radians
 */
export function degToRad( degrees ) {
  return degrees * ( Math.PI / 180 );
}

/**
 * Radians to Degrees conversion
 *
 * @param {number} radians
 * @return {number} degrees
 */
export function radToDeg( radians ) {
  return radians * ( 180 / Math.PI );
}

/**
 * Calculate X coordinate along circle path
 *
 * @param {number} radius
 * @param {number} radians
 * @return {number} x coordinate
 */
export function calcCircX (radius, radians) {
  return radius * Math.cos(radians);
}

/**
 * Calculate Y coordinate along circle path
 *
 * @param {number} radius
 * @param {number} radians
 * @return {number} y coordinate
 */
export function calcCircY (radius, radians) {
  return radius * Math.sin(radians);
}

/**
 * Distance Formula
 *
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @return {number} distance
 */
export function calcDistance (x1, y1, x2, y2) {
  return Math.sqrt( (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1) );
}

/**
 * Number Map
 *
 * @param {number} input
 * @param {number} inputStart
 * @param {number} inputStop
 * @param {number} outputStart
 * @param {number} outputStop
 * @return {number} output
 */

export function numberMap (input, inputStart, inputStop, outputStart, outputStop) {
  return outputStart + (outputStop - outputStart) * ((input - inputStart) / (inputStop - inputStart));
}
