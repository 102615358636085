const ROTATION_COUNT = 5;

let config = {
  POKE_DATA_INDEX: 0,
  POKE_DATA_NAME: 2,
  POKE_DATA_VOTES: 4,
  POKE_DATA_NAME_LOCALIZED: {
    'en': 2,
    'it': 5,
    'es': 6,
    'fr': 7,
    'de': 8
  },
  POKE_REGION_KEYS: [
    "All",
    "Kanto",
    "Johto",
    "Hoenn",
    "Sinnoh",
    "Unova",
    "Kalos",
    "Alola",
    "Galar"
  ],
  ROUND: 100000,
  CIRCLE_INTERACTION_EASE: 2,
  CIRCLE_RELEASE_EASE: 15,
  ROTATION_COUNT: ROTATION_COUNT,
  RADIUS: 324,
  START_ANGLE: 90,
  END_ANGLE: 450,
  CIRC_LINE_WIDTH: 10,
  CIRC_TIMELINE_DURATION: 3000,
  ARC_POINT_COUNT: 90 * ROTATION_COUNT,
  ARC_INDEX_START_OFFSET: 10,
  ARC_INDEX_THRESHOLD: 0.2 / ROTATION_COUNT,
  ANIM_POINT_COUNT: 360 * ROTATION_COUNT,
  MAX_DRAG_DISTANCE: 250
};

export default config;
