import components from '../utilities/components';

let LeavingSite = function () {
  if (LeavingSite.prototype._singletonInstance) {
    return LeavingSite.prototype._singletonInstance;
  }
  LeavingSite.prototype._singletonInstance = this;

  let $component = $('#leaving-site-component');
  let $close = $component.find('.button-close');
  let $cancel = $component.find('.button-cancel');
  let $confirm = $component.find('.button-confirm');
  let activeTarget = null;
  let blank = false;
  let hostname = window.location.hostname;
  let whitelist = [
    'pokemon.com',
    'pokemoncenter.com',
    'pokemon-gl.com'
  ];

  $close.on('click', () => {
    components.close($component);
    activeTarget = null;
  });

  $cancel.on('click', () => {
    components.close($component);
    activeTarget = null;
  });

  $confirm.on('click', () => {
    components.close($component);
    activeTarget = null;
  });

  // $(document.body).on('click', 'a:not([data-standard-link])', onLinkClick);
  $(document.body).on('click', '[data-href]', onLinkClick);

  function onLinkClick(e) {

    // don't process cancel/continue button on the components
    if ($component.hasClass('is-active') || $component.hasClass('is-visible')) {
      return;
    }

    // get link info to attach to leaving site component
    let target = e.currentTarget;

    // get data-button attribute for analytics
    let dataButton = target.getAttribute('data-button');
    console.log('dataButton is ' + dataButton);
    if ( dataButton !== null && dataButton !== "" ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'button-click',
        'buttonName': dataButton
      });
      console.log('datalayer push ' + dataButton);
    }
    let url = target.getAttribute('data-href');
    activeTarget = url;
    $confirm.attr('href', url);

    if ( dataButton !== null && dataButton !== "" ) {
      $confirm.attr('data-button', dataButton);
    }
    components.open($component);
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
};

function inArray (str, array) {
  var inArray = false;
  if (array) {
    for (var i = 0, ii = array.length; i < ii; i++) {
      if (('' + str).indexOf(array[i]) !== -1) {
        inArray = true;
        break;
      }
    }
  }
  return inArray;
}

export default LeavingSite;
