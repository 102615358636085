import * as PIXI from 'pixi.js';
import each from 'lodash/each';
import Stats from "stats.js";
import {
  qs,
  degToRad,
  calcCircX,
  calcCircY,
  calcDistance,
  numberMap
} from "../utilities";
import config from "../config";
import DataManager from "./data-manager";
import HeroTimeline from "./hero-timeline";
import Confetti from "./confetti";

const {
  POKE_REGION_KEYS,
  ROUND,
  CIRCLE_INTERACTION_EASE,
  CIRCLE_RELEASE_EASE,
  ROTATION_COUNT,
  RADIUS,
  START_ANGLE,
  END_ANGLE,
  CIRC_LINE_WIDTH,
  ARC_POINT_COUNT,
  ARC_INDEX_START_OFFSET,
  ARC_INDEX_THRESHOLD,
  ANIM_POINT_COUNT,
  MAX_DRAG_DISTANCE
} = config;

// don't think we need an intro loop but leave it for now
const UPDATE_INTRO = 0;
const UPDATE_USER_INTERACTIVE = 1;
const UPDATE_REGION_SWITCH = 2;
const UPDATE_COMPLETE = 3;

let dataManager;

class Hero {
  constructor(props) {
    window.dataLayer = window.dataLayer || [];

    let hero = this;

    // selectors
    let parent = document.getElementById('hero');
    let loader = qs('.loader-wrap', parent);
    let wrap = document.getElementById('hero-canvas-wrap');
    let canvas = document.getElementById('hero-canvas');
    let scrollHandles = document.getElementById('invisible-scroll-handles');
    let replay = document.getElementById('replay-countdown-button');
    let finalUrls = document.getElementById('final-pokemon-url');
    let debug = document.getElementById('hero-debug');

    // access our data manager
    dataManager = new DataManager();

    // always start with All
    hero.activeRegion = POKE_REGION_KEYS[0];
    hero.topFive = dataManager.getTopFiveObjects(hero.activeRegion);
    window.dataLayer.push({
      'event': 'filter-select',
      'regionName': hero.activeRegion //ex. NA, LATAM, etc.
    });
    // console.log('Track Region', hero.activeRegion);

    // store our selected elements
    hero.elements = {
      parent,
      loader,
      canvas,
      scrollHandles,
      replay,
      finalUrls,
      debug
    };
    // console.log(hero.elements);

    // hero update type
    // hero.updateType = UPDATE_INTRO;
    hero.updateType = UPDATE_USER_INTERACTIVE;

    // is a replay available
    // only available after rotating through all characters in a region
    hero.replayAvailable = false;

    // keep track of our pointer
    hero.pointer = {
      x: 0,
      y: 0,
      down: false
    };

    // keep track of our progress around the circle
    hero.progress = {
      target: 0,
      actual: 0,
      smooth:0
    };
    hero.rotationsComplete = 0;

    // interaction flag
    hero.interactionActive = false;

    // calculate our points around the circle used for touch interaction
    hero.arcPoints = [];
    hero.arcIndex = 0;
    let arcIncrement = (360 * ROTATION_COUNT) / ARC_POINT_COUNT;
    for(let i = 0; i < ARC_POINT_COUNT; i++) {
      let arcPointAngle = i * arcIncrement + START_ANGLE;
      let arcPointX = calcCircX(RADIUS, degToRad(arcPointAngle));
      let arcPointY = calcCircY(RADIUS, degToRad(arcPointAngle));
      hero.arcPoints.push({
        x: arcPointX,
        y: arcPointY
      });
    }
    hero.arcPoints.push(hero.arcPoints[0]);

    // calculate our points around the circle used for animation
    let animationIncrement = (360 * ROTATION_COUNT) / ANIM_POINT_COUNT;
    hero.animationPoints = [];
    for(let i = 0; i < ANIM_POINT_COUNT; i++) {
      let animationPointAngle = i * animationIncrement + START_ANGLE;
      let animationPointX = calcCircX(RADIUS, degToRad(animationPointAngle));
      let animationPointY = calcCircY(RADIUS, degToRad(animationPointAngle));
      hero.animationPoints.push({
        x: animationPointX,
        y: animationPointY
      });
    }
    hero.animationPoints.push(hero.animationPoints[0]);

    // create our pixi application
    const app = new PIXI.Application({
      view: canvas,
      width: wrap.offsetWidth,
      height: wrap.offsetHeight,
      // backgroundColor: 0x35c0de,
      transparent: true,
      antialias: true,
      resizeTo: wrap
    });
    hero.app = app;

    if (__DEV__) {
      let stats = new Stats();
      stats.dom.style.top = '62px';
      document.body.appendChild(stats.dom);
      hero.stats = stats;
    }

    // loading logic
    let ext = Modernizr.webp ? '.webp' : '.png';
    app.loader
      .add('drag-handle', '../assets/images/home/hero/dial-button-start' + ext)
      .add('drag-handle-down', '../assets/images/home/hero/dial-button-over' + ext)
      .add('drag-handle-arrow', '../assets/images/home/hero/dial-button-arrow-start' + ext)
      .add('drag-handle-arrow-light', '../assets/images/home/hero/dial-button-arrow-end' + ext)
      .add('pokeball', '../assets/images/home/hero/pokeball' + ext)
      .add('drag-track', '../assets/images/home/hero/drag-track' + ext)
      .add('start-marker', '../assets/images/home/hero/start-marker' + ext)
      .add('disk-white', '../assets/images/home/hero/disk-white' + ext)
      .add('disk-yellow', '../assets/images/home/hero/disk-yellow' + ext)
      .add('one', '../assets/images/home/hero/one' + ext)
      .add('cursor-flash', '../assets/images/home/hero/cursor-flash' + ext)
      .add('cursor-hand', '../assets/images/home/hero/cursor-hand' + ext)
      .add('peach', '../assets/images/home/hero/big-peach' + (Modernizr.webp ? '.webp' : '.jpg'));

    // load all hero pokemon upfront
    let allTopFiveNames = dataManager.getAllTopFiveNames();
    each(allTopFiveNames, pokemonName => {
      app.loader.add(pokemonName, '../assets/images/home/hero/pokemon/' + pokemonName + ext);
      app.loader.add(pokemonName + '-shadow', '../assets/images/home/hero/pokemon-shadow/' + pokemonName + ext);
    });

    app.loader.load((e) => {
      loader.classList.add('is-hidden');
      hero.ready();
    });

    // console.log('Constructor', this);
  }
  ready () {
    let hero = this;
    let app = hero.app;
    let { replay, canvas } = hero.elements;

    canvas.addEventListener('pointerdown', (e) => {
      requestAnimationFrame(() => {
        hero.pointer.down = true;
        hero.updatePointer(e);
        // console.log('pointerdown', `x: ${hero.pointer.x}, y: ${hero.pointer.y}`);
      })
    });

    canvas.addEventListener('pointerup', (e) => {
      requestAnimationFrame(() => {
        if (hero.pointer.down) {
          hero.pointer.down = false;
          hero.updatePointer(e);
          hero.interactionEnd();
          // console.log('pointerup');
        }
      });
    });

    canvas.addEventListener('pointerleave', () => {
      requestAnimationFrame(() => {
        if (hero.pointer.down) {
          hero.pointer.down = false;
          hero.interactionEnd();
          // console.log('pointerleave', `x: ${hero.pointer.x}, y: ${hero.pointer.y}`);
        }
      });
    });

    canvas.addEventListener('pointermove', (e) => {
      requestAnimationFrame(() => {
        if (hero.pointer.down) {
          hero.updatePointer(e);
          // hero.interaction.move();
        }
      });
    });

    let container = new PIXI.Container();
    app.stage.addChild(container);

    let pokeShadowSprites = [];
    each(hero.topFive, (pokemon, i) => {
      let sprite = PIXI.Sprite.from(pokemon.name + '-shadow');
      sprite.anchor.set(0.5);
      sprite.scale.set(0.5);
      sprite.alpha = 0;
      container.addChild(sprite);
      pokeShadowSprites.push(sprite);
    });

    let yellowDiskContainer = new PIXI.Container();
    container.addChild(yellowDiskContainer);
    let yellowDisk1 = PIXI.Sprite.from('disk-yellow');
    yellowDisk1.anchor.set(0.5);
    let yellowDisk2 = PIXI.Sprite.from('disk-yellow');
    yellowDisk2.anchor.set(0.5);
    yellowDiskContainer.addChild(yellowDisk1);
    yellowDiskContainer.addChild(yellowDisk2);

    let whiteDiskContainer = new PIXI.Container();
    container.addChild(whiteDiskContainer);
    let whiteDisk1 = PIXI.Sprite.from('disk-white');
    whiteDisk1.anchor.set(0.5);
    whiteDiskContainer.addChild(whiteDisk1);

    let one = PIXI.Sprite.from('one');
    one.anchor.set(0.5);
    one.scale.set(0.5);
    one.alpha = 0;
    container.addChild(one);

    let track = PIXI.Sprite.from('drag-track');
    track.anchor.set(0.5);
    track.alpha = 0;
    container.addChild(track);

    let peach = PIXI.Sprite.from('peach');
    peach.anchor.set(0.5);
    peach.alpha = 0;
    container.addChild(peach);

    let startMarker = PIXI.Sprite.from('start-marker');
    startMarker.anchor.set(0.5);
    startMarker.alpha = 0;
    container.addChild(startMarker);
    startMarker.x = hero.arcPoints[0].x;
    startMarker.y = hero.arcPoints[0].y;

    let pokeSprites = [];
    each(hero.topFive, (pokemon, i) => {
      let sprite = PIXI.Sprite.from(pokemon.name);
      sprite.anchor.set(0.5);
      sprite.scale.set(0.5);
      sprite.alpha = 0;
      // TODO: Try colorize black instead of tint
      // sprite.tint = 0x000000;
      container.addChild(sprite);
      pokeSprites.push(sprite);
    });

    let handle = PIXI.Sprite.from('drag-handle');
    handle.anchor.set(0.5);
    handle.alpha = 0;
    container.addChild(handle);
    handle.x = hero.arcPoints[0].x;
    handle.y = hero.arcPoints[0].y;
    let pokeball = PIXI.Sprite.from('pokeball');
    pokeball.anchor.set(0.5);
    pokeball.alpha = 0;
    container.addChild(pokeball);
    pokeball.x = handle.x;
    pokeball.y = handle.y;
    let arrowLight = PIXI.Sprite.from('drag-handle-arrow-light');
    arrowLight.anchor.set(0.5);
    arrowLight.alpha = 0;
    container.addChild(arrowLight);
    arrowLight.x = handle.x;
    arrowLight.y = handle.y;
    let arrow = PIXI.Sprite.from('drag-handle-arrow');
    arrow.anchor.set(0.5);
    arrow.alpha = 0;
    container.addChild(arrow);
    arrow.x = handle.x;
    arrow.y = handle.y;

    let cursorHand = PIXI.Sprite.from('cursor-hand');
    cursorHand.anchor.set(0.5);
    cursorHand.position.set(-280, 370);
    cursorHand.alpha = 0;
    container.addChild(cursorHand);
    let cursorFlash = PIXI.Sprite.from('cursor-flash');
    cursorFlash.anchor.set(0.5);
    cursorFlash.position.set(-280, 370);
    container.addChild(cursorFlash);
    cursorFlash.alpha = 0;

    let graphics = new PIXI.Graphics();
    container.addChild(graphics);
    peach.mask = graphics;

    let confettiContainer = new PIXI.Container();
    container.addChild(confettiContainer);

    container.scale.set(hero.scale);
    container.position.set(hero.centerX, hero.centerY);
    // console.log(container);

    hero.entities = {
      container,
      graphics,
      handle,
      pokeball,
      arrow,
      arrowLight,
      track,
      peach,
      pokeSprites,
      pokeShadowSprites,
      startMarker,
      yellowDiskContainer,
      yellowDisk1,
      yellowDisk2,
      whiteDiskContainer,
      whiteDisk1,
      one,
      cursorHand,
      cursorFlash
    };

    // initialize our timeline manager
    hero.timeline = new HeroTimeline( hero.entities );
    hero.timeline.setRegion( hero.activeRegion );

    hero.timeline.playIntro();

    // initialize our confetti manager
    hero.confetti = new Confetti( hero.app.renderer, confettiContainer, { x: startMarker.x, y: startMarker.y } );

    // app.ticker.add(hero.update.bind(hero));
    app.ticker.add((delta) => {
      switch (hero.updateType) {
        case UPDATE_INTRO:
          // don't think we need this anymore
          break;
        case UPDATE_USER_INTERACTIVE:
          hero.updateUserInteractive(delta);
          break;
      }
    });

    replay.addEventListener('click', function () {
      if (hero.replayAvailable) {
        hero.setRegion( hero.activeRegion );
        hero.onReplayButton();
      }
    });

    // temp
    // hero.confetti.start();
  }
  allRotationsComplete () {
    let hero = this;
    let { replay, finalUrls } = hero.elements;
    hero.confetti.start();
    replay.classList.add('is-active');
    replay.clientHeight;
    replay.classList.add('is-visible');
    hero.replayAvailable = true;
    hero.timeline.hideTrack();
    hero.onAllRotationsComplete();

    let pokemon = hero.topFive[0].name;
    pokemon = pokemon.replace("'", "");
    pokemon = pokemon.replace(" ", "_");
    let pokemonUrl = finalUrls.querySelector(`[data-final-pokemon="${pokemon}"]`);
    finalUrls.classList.add('is-active');
    pokemonUrl.classList.add('is-active');

    // console.log('All Rotations Complete!!!');
  }
  setRegion ( region ) {
    window.dataLayer.push({
      'event': 'filter-select',
      'regionName': region //ex. NA, LATAM, etc.
    });
    // console.log('Track Region', region);
    let hero = this;
    let { pokeSprites, pokeShadowSprites } = hero.entities;
    let { replay, finalUrls } = hero.elements;
    finalUrls.classList.remove('is-active');
    let finalPokemonUrl = finalUrls.querySelector('.is-active');
    if (finalPokemonUrl) {
      finalPokemonUrl.classList.remove('is-active');
    }
    hero.confetti.stop();
    // turn off replay button
    hero.replayAvailable = false;
    replay.classList.remove('is-visible');
    setTimeout(function () {
      replay.classList.remove('is-active');
    }, 500);
    // set our new active region
    hero.activeRegion = region;
    hero.topFive = dataManager.getTopFiveObjects(region);
    hero.progress.target = 0;
    hero.progress.actual = 0;
    hero.progress.smooth = 0;
    hero.rotationsComplete = 0;
    hero.arcIndex = 0;
    hero.timeline.setRegion( hero.activeRegion );
    hero.timeline.showTrack();
    each(pokeSprites, (sprite, i) => {
      let name = hero.topFive[i].name;
      sprite.texture = PIXI.Texture.from(name);
      pokeShadowSprites[i].texture = PIXI.Texture.from(name + '-shadow');
    });
  }
  interactionStart () {
    let hero = this;
    let { handle } = hero.entities;
    let { scrollHandles } = hero.elements;

    if (!hero.interactionActive) {
      hero.interactionActive = true;
      scrollHandles.classList.add('is-disabled');
      handle.texture = PIXI.Texture.from('drag-handle-down');
      // console.log('Interaction start');
    }
  }
  interactionEnd () {
    let hero = this;
    let { handle } = hero.entities;
    let { scrollHandles } = hero.elements;

    if (hero.interactionActive) {
      hero.interactionActive = false;
      scrollHandles.classList.remove('is-disabled');
      handle.texture = PIXI.Texture.from('drag-handle');
      // console.log('Interaction end');
    }
  }
  updatePointer (e) {
    let hero = this;
    let { canvas } = hero.elements;
    let rect = canvas.getBoundingClientRect();
    let pointerX = e.clientX - rect.left;
    let pointerY = e.clientY - rect.top;

    hero.pointer.x = (-hero.centerX + pointerX) * hero.scaleInverse;
    hero.pointer.y = (-hero.centerY + pointerY) * hero.scaleInverse;

    // console.log(`Pointer X: ${pointerX} Pointer Y: ${pointerY}`);
  }
  updateIntro (delta) {
    // don't think we need this anymore
  }
  updateUserInteractive (delta) {
    let hero = this;
    let progress = hero.progress;
    let { container, graphics } = hero.entities;

    // START STATS - for tracking app performance (Frames Per Second)
    if (hero.stats) {
      hero.stats.begin();
    }

    container.scale.set(hero.scale);
    container.position.set(hero.centerX, hero.centerY);

    hero.confetti.update(delta);

    graphics.clear();

    // calc arcIndex
    if (hero.pointer.down) {
      hero.timeline.stopHandleCTA();
      let x1 = hero.pointer.x;
      let y1 = hero.pointer.y;
      let x2 = hero.arcPoints[hero.arcIndex].x;
      let y2 = hero.arcPoints[hero.arcIndex].y;
      let distance = calcDistance(x1, y1, x2, y2);
      each(hero.arcPoints, (arcPoint, index) => {

        // Minimun index check based on rations complete
        let minIndex = hero.rotationsComplete * (ARC_POINT_COUNT / ROTATION_COUNT);
        if (index < minIndex) {
          return;
        }

        // Index threshold check
        let indexThreshold = ~~(hero.arcPoints.length * ARC_INDEX_THRESHOLD);
        let indexDistance = Math.abs(hero.arcIndex - index);
        if (indexDistance > indexThreshold) {
          return;
        }

        x2 = arcPoint.x;
        y2 = arcPoint.y;
        let newDistance = calcDistance(x1, y1, x2, y2);
        if (newDistance <= MAX_DRAG_DISTANCE && newDistance < distance) {
          hero.arcIndex = index;
          distance = newDistance;
          hero.arcIndexSet = true;
        }
      });
    } else {
      hero.arcIndexSet = false;
      if (hero.rotationsComplete < ROTATION_COUNT) {
        hero.timeline.playHandleCTA();
        hero.arcIndex = hero.rotationsComplete * (ARC_POINT_COUNT / ROTATION_COUNT) + ARC_INDEX_START_OFFSET;
      } else {
        hero.arcIndex = hero.rotationsComplete * (ARC_POINT_COUNT / ROTATION_COUNT);
      }
    }

    if (hero.pointer.down && hero.arcIndexSet) {
      hero.interactionStart();
    }

    progress.target = hero.arcIndex / (hero.arcPoints.length - 1);
    progress.actual -= (progress.actual - progress.target) / hero.ease;
    progress.smooth = Math.round(progress.actual * ROUND) / ROUND;

    let totalRotation = 360 * ROTATION_COUNT;
    let rotationCount = Math.floor(totalRotation * progress.smooth / 360);
    if (rotationCount > hero.rotationsComplete) {
      hero.rotationsComplete = rotationCount;
      // console.log('Rotations Complete', hero.rotationsComplete);
      let trackingCount = ROTATION_COUNT + 1 - hero.rotationsComplete;
      let trackingName = hero.topFive[hero.topFive.length - hero.rotationsComplete].name;
      window.dataLayer.push({
        'event': 'pokemon-reveal',
        'pokemonName': trackingName, //ex. 'Charmander', 'Chikorita', etc.
        'arrowClick': trackingCount //ex. 'left', 'right', 'none'
      });
      // console.log('Track Pokemon Count: ', trackingCount);
      // console.log('Track Pokemon Name: ', trackingName);
      if (hero.rotationsComplete === ROTATION_COUNT) {
        // console.log('All Rotations Complete');
        hero.allRotationsComplete();
      }
    }

    let animationIndex = Math.round(progress.smooth * (hero.animationPoints.length - 1));
    hero.setHandle(hero.animationPoints[animationIndex]);

    // draw cursor pointer (for debugging)
    // graphics.lineStyle(0,0x000000);
    // graphics.beginFill(0x33baac, 1);
    // graphics.drawCircle(hero.pointer.x, hero.pointer.y, 20);
    // graphics.endFill();
    // end cursor debug

    let arcAngle = 0;
    if (progress.smooth < 0.2) {
      arcAngle = numberMap(progress.smooth, 0, 0.2, START_ANGLE, END_ANGLE);
    } else if (progress.smooth < 0.4) {
      arcAngle = numberMap(progress.smooth, 0.2, 0.4, START_ANGLE, END_ANGLE);
    } else if (progress.smooth < 0.6) {
      arcAngle = numberMap(progress.smooth, 0.4, 0.6, START_ANGLE, END_ANGLE);
    } else if (progress.smooth < 0.8) {
      arcAngle = numberMap(progress.smooth, 0.6, 0.8, START_ANGLE, END_ANGLE);
    } else {
      arcAngle = numberMap(progress.smooth, 0.8, 1, START_ANGLE, END_ANGLE);
    }

    // arcAngle = numberMap(progress, 0, 1, START_ANGLE, END_ANGLE);
    graphics.lineStyle(CIRC_LINE_WIDTH,0x000000);
    graphics.arc(0, 0, RADIUS, degToRad(START_ANGLE), degToRad(arcAngle));
    graphics.lineStyle(0,0x000000);

    // arc end marker (for debugging)
    // let arcPointAngle = progress;
    // let arcPointX = calcCircX(RADIUS, degToRad(arcPointAngle));
    // let arcPointY = calcCircY(RADIUS, degToRad(arcPointAngle));
    // graphics.beginFill(0xe20754, 1);
    // graphics.drawCircle( arcPointX,  arcPointY, 20);
    // graphics.endFill();

    // draw points around our track (for debugging)
    // each(hero.arcPoints, (arcPoint, i) => {
    //   graphics.beginFill(0x000000, 1);
    //   graphics.drawCircle( arcPoint.x,  arcPoint.y, 4 + i);
    //   graphics.endFill();
    // });

    hero.timeline.setProgress( progress.smooth );

    // hero.debug(
    //   'arcIndex: ' + hero.arcIndex + '   ' +
    //   'animationIndex: ' + animationIndex + '   ' +
    //   'Progress: ' + progress.smooth
    // );

    // END STATS - for tracking app performance (Frames Per Second)
    if (hero.stats) {
      hero.stats.end();
    }
  }
  setHandle ( position ) {
    let hero = this;
    let { handle, pokeball, arrow, arrowLight } = hero.entities;
    handle.x = position.x;
    handle.y = position.y;
    pokeball.x = position.x;
    pokeball.y = position.y;
    arrow.x = position.x;
    arrow.y = position.y;
    arrowLight.x = position.x;
    arrowLight.y = position.y;
    let progress = hero.progress.smooth - 1 / ROTATION_COUNT * hero.rotationsComplete;
    let angle = numberMap(progress, 0, 0.2, 10, 370);
    let alpha = numberMap(progress, 0, 0.2, 1, 0);
    arrow.angle = angle;
    arrowLight.angle = angle;
    if (hero.rotationsComplete !== ROTATION_COUNT) {
      arrow.alpha = alpha;
    }

    // arrow.angle = numberMap()
  }
  debug (text) {
    let hero = this;
    let { debug } = hero.elements;
    if (hero.previousDebugText !== text) {
      debug.innerText = text;
      hero.previousDebugText = text;
    }
  }
  get ease () {
    let hero = this;
    // if (hero.pointer.down) {
    if (hero.arcIndexSet) {
      return CIRCLE_INTERACTION_EASE
    }
    return CIRCLE_RELEASE_EASE
  }
  get centerX () {
    return this.app.screen.width / 2;
  }
  get centerY () {
    return this.app.screen.height / 2;
  }
  get scale () {
    let scale = 1;
    if (window.innerWidth < 1366) {
      scale = window.innerWidth / 1366;
    }
    if (window.innerWidth < 1024) {
      scale = window.innerWidth / 1024;
    }
    return scale;
  }
  get scaleInverse () {
    return 1 / this.scale
  }
}

export default Hero;
