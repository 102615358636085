import config from "../config";
import each from 'lodash/each';

const {
  POKE_REGION_KEYS
} = config;

class PokemonListManager {
  constructor() {
    let manager = this;
    let parent = document.getElementById('pokemon-region-list');
    manager.elements = {
      parent
    };

    // always start with All
    parent.setAttribute('data-active-region', POKE_REGION_KEYS[0]);
    manager.initializeObservers();
  }
  setRegion ( region ) {
    let manager = this;
    let {
      parent
    } = manager.elements;
    parent.setAttribute('data-active-region', region);
  }
  showFullResults () {
    let manager = this;
    let {
      parent
    } = manager.elements;

    //loop here
    let region = parent.getAttribute('data-active-region');
    let regionElement = parent.querySelector('[data-region="' + region + '"]');
    if (regionElement.getAttribute('data-images-loaded') !== 'true') {
      // console.log('Load Images');
      let images = regionElement.querySelectorAll('.pokemon-image');
      let extension = '.png';
      if (Modernizr.webp) {
        extension = '.webp';
      }
      each(images, function(tmpImage, index) {
        // console.log('image is ' + tmpImage.getAttribute('data-image-src') );
        tmpImage.style.backgroundImage = "url('" + tmpImage.getAttribute('data-image-src') + extension + "')";
      });
      regionElement.setAttribute('data-images-loaded', 'true');
    }

    parent.classList.add('is-active');
  }
  hideFullResults () {
    let manager = this;
    let {
      parent
    } = manager.elements;
    parent.classList.remove('is-active');
  }
  initializeObservers () {
    // console.log('initializeObservers');
    let manager = this;
    let {
      parent
    } = manager.elements;
    let all = parent.querySelectorAll('a.pokemon');
    // console.log('all pokemon is ', all);

    let options = {
      root: null,
      rootMargin: "0px",
      // threshold: [0, 0.25, 0.5, 0.75, 1]
      threshold: 0.5
    };
    let characterObserver = new IntersectionObserver(manager.handleContentIntersect, options);

    each(all, function(character, index) {
      characterObserver.observe(character);
    });

  }
  handleContentIntersect (entries) {
    // console.log('handle intersect ', entries);
    each(entries, function(entry, index) {
      if(entry.intersectionRatio >= 0.5 && entry.target.classList.contains('is-active') === false) {
        entry.target.classList.add('is-active');
      } else if (entry.intersectionRatio < 0.5 && entry.target.classList.contains('is-active') && entry.boundingClientRect.top > 0) {
        entry.target.classList.remove('is-active');
      }
    });
  }
}

export default PokemonListManager;
