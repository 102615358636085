import POKEMON_DATA from "../data";
import config from "../config";
import each from 'lodash/each';

const {
  POKE_DATA_INDEX,
  POKE_DATA_NAME,
  POKE_DATA_VOTES,
  POKE_DATA_NAME_LOCALIZED
} = config;

let localizedNameIndex;

// console.log(POKEMON_DATA);

let DataManager = function () {
  if (DataManager.prototype._singletonInstance) {
    return DataManager.prototype._singletonInstance;
  }

  DataManager.prototype._singletonInstance = this;

  let lang = document.documentElement.lang;
  localizedNameIndex = POKE_DATA_NAME_LOCALIZED[lang];
};

DataManager.prototype.getTopFiveObjects = function (region) {
  let regionData = POKEMON_DATA[region].slice(0, 5);
  let topFive = [];
  each(regionData, (pokemon, i) => {
    let name = pokemon[POKE_DATA_NAME];
    // Filename fix for Sirfetch'd
    name = name.replace("'", "");
    topFive.push({
      name: name,
      localized: pokemon[localizedNameIndex],
      votes: pokemon[POKE_DATA_VOTES]
    });
  });
  return topFive;
};

DataManager.prototype.getAllTopFiveNames = function () {
  let allTopFive = [];
  each(POKEMON_DATA, region => {
    let topFive = region.slice(0, 5);
    each(topFive, pokemon => {
      let name = pokemon[POKE_DATA_NAME];
      // Filename fix for Sirfetch'd
      name = name.replace("'", "");
      if (allTopFive.indexOf(name) === -1) {
        allTopFive.push(name);
      }
    });
  });
  return allTopFive;
};

DataManager.prototype.getObjects = function (region) {
  let regionData = POKEMON_DATA[region];
  let objs = [];
  each(regionData, (pokemon, i) => {
    let name = pokemon[POKE_DATA_NAME];
    // Filename fix for Sirfetch'd
    name = name.replace("'", "");
    objs.push({
      pokeIndex: pokemon[POKE_DATA_INDEX],
      name: name,
      localized: pokemon[localizedNameIndex],
      votes: pokemon[POKE_DATA_VOTES]
    });
  });
  return objs;
};

export default DataManager;
