import VideoPlayer from "./components/video";
import LeavingSite from "./components/leaving-site";
import LocaleSelector from "./components/locale-selector";
import ScrollArrow from "./components/scroll-arrow";

import smoothscroll from "./components/smoothscroll";
import Home from "./home";

if (__DEV__) {
  console.log('DEVELOPMENT ENVIRONMENT');
}
if (__SRC__) {
  console.log('SOURCE ENVIRONMENT');
}
if (__DIST__) {
  console.log('DISTRIBUTION ENVIRONMENT');
}

console.log('VERSION', __VERSION__);

$(function(){
  $(".pokemon-gus-container").GlobalUtilityStrip();

  // initialize the smooth scrolling polyfill
  smoothscroll.polyfill();

  document.body.classList.add('dom-ready');

  let page = document.body.getAttribute('data-page');

  switch (page) {
    case 'home':
      let home = new Home();
      break;
  }

  window.trailer = new VideoPlayer();
  new LeavingSite();
  new LocaleSelector();
  // new WatercolorFx();
  // new MobileNav();
  // new IsVisible();
  new ScrollArrow();

  // let desktopNav = document.getElementById('desktop-nav');
  // desktopNav.querySelector(`.nav-${page}`).classList.add('is-active');
  //

});
