import components from '../utilities/components';

// TODO: Make this dynamic!!!

let VideoPlayer = function () {
  if (VideoPlayer.prototype._singletonInstance) {
    return VideoPlayer.prototype._singletonInstance;
  }
  VideoPlayer.prototype._singletonInstance = this;

  this.$component = $('#video-player-component');
  this.$close = this.$component.find('.button-close');

  this.$close.on('click', this.close.bind(this));

  $('#open-video-player').on('click', () => {
    console.log('clicked open video');
    this.open();
  });

  // this.initialize();
};

VideoPlayer.prototype.initialize = function () {

  // let tag = document.createElement('script');
  //
  // tag.src = "https://www.youtube.com/iframe_api";
  // let firstScriptTag = document.getElementsByTagName('script')[0];
  // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // window.onYouTubeIframeAPIReady = () => {
  //   this.player = new YT.Player('yt-node', {
  //     height: '100%',
  //     width: '100%',
  //     videoId: this.$component.attr('data-yt'),
  //     playerVars: {
  //       rel: 0,
  //       autoplay: 0,
  //       autohide: 2,
  //       controls: 2,
  //       modestbranding: 1,
  //       showinfo: 1,
  //       iv_load_policy: 3
  //     },
  //     events: {
  //       onReady: () => {
  //         // console.log('VideoPlayer initalized!');
  //         if (this.isOpen) {
  //           this.player.playVideo();
  //         }
  //       }
  //     }
  //   });
  // };

  if ($('#open-video-player').length) {
    this.player = new YT.Player('yt-node', {
      height: '100%',
      width: '100%',
      videoId: $('#open-video-player').attr('data-yt'),
      playerVars: {
        rel: 0,
        autoplay: 0,
        autohide: 2,
        controls: 2,
        modestbranding: 1,
        showinfo: 1,
        iv_load_policy: 3
      },
      events: {
        onReady: () => {
          console.log('VideoPlayer initalized!');
          if (this.isOpen) {
            this.player.playVideo();
          }
        }
      }
    });
  }
};

VideoPlayer.prototype.open = function () {
  components.open(this.$component);
  this.isOpen = true;
  if (!this.player) {
    this.initialize();
  } else if (this.player && this.player.playVideo) {
    this.player.playVideo();
  }
};

VideoPlayer.prototype.close = function () {
  this.isOpen = false;
  if (this.player && this.player.stopVideo) {
    this.player.stopVideo();
  }
  components.close(this.$component);
};

export default VideoPlayer;
