const POKEMON_DATA = {
  "All": [
    ["658", "Kalos", "Greninja", "ゲッコウガ", "140559", "Greninja", "Greninja", "Amphinobi", "Quajutsu"],
    ["448", "Sinnoh", "Lucario", "ルカリオ", "102259", "Lucario", "Lucario", "Lucario", "Lucario"],
    ["778", "Alola", "Mimikyu", "ミミッキュ", "99077", "Mimikyu", "Mimikyu", "Mimiqui", "Mimigma"],
    ["6", "Kanto", "Charizard", "リザードン", "93968", "Charizard", "Charizard", "Dracaufeu", "Glurak"],
    ["197", "Johto", "Umbreon", "ブラッキー", "67062", "Umbreon", "Umbreon", "Noctali", "Nachtara"],
    ["700", "Kalos", "Sylveon", "ニンフィア", "66029", "Sylveon", "Sylveon", "Nymphali", "Feelinara"],
    ["445", "Sinnoh", "Garchomp", "ガブリアス", "61877", "Garchomp", "Garchomp", "Carchacrok", "Knakrack"],
    ["384", "Hoenn", "Rayquaza", "レックウザ", "60939", "Rayquaza", "Rayquaza", "Rayquaza", "Rayquaza"],
    ["282", "Hoenn", "Gardevoir", "サーナイト", "60596", "Gardevoir", "Gardevoir", "Gardevoir", "Guardevoir"],
    ["94", "Kanto", "Gengar", "ゲンガー", "60214", "Gengar", "Gengar", "Ectoplasma", "Gengar"],
    ["887", "Galar", "Dragapult", "ドラパルト", "57973", "Dragapult", "Dragapult", "Lanssorien", "Katapuldra"],
    ["248", "Johto", "Tyranitar", "バンギラス", "56834", "Tyranitar", "Tyranitar", "Tyranocif", "Despotar"],
    ["1", "Kanto", "Bulbasaur", "フシギダネ", "56015", "Bulbasaur", "Bulbasaur", "Bulbizarre", "Bisasam"],
    ["849", "Galar", "Toxtricity", "ストリンダー", "55032", "Toxtricity", "Toxtricity", "Salarsen", "Riffex"],
    ["249", "Johto", "Lugia", "ルギア", "53268", "Lugia", "Lugia", "Lugia", "Lugia"],
    ["722", "Alola", "Rowlet", "モクロー", "52367", "Rowlet", "Rowlet", "Brindibou", "Bauz"],
    ["681", "Kalos", "Aegislash", "ギルガルド", "51517", "Aegislash", "Aegislash", "Exagide", "Durengard"],
    ["609", "Unova", "Chandelure", "シャンデラ", "50943", "Chandelure", "Chandelure", "Lugulabre", "Skelabra"],
    ["25", "Kanto", "Pikachu", "ピカチュウ", "48060", "Pikachu", "Pikachu", "Pikachu", "Pikachu"],
    ["133", "Kanto", "Eevee", "イーブイ", "47762", "Eevee", "Eevee", "Évoli ", "Evoli"],
    ["405", "Sinnoh", "Luxray", "レントラー", "46032", "Luxray", "Luxray", "Luxray", "Luxtra"],
    ["724", "Alola", "Decidueye", "ジュナイパー", "44011", "Decidueye", "Decidueye", "Archéduc", "Silvarro"],
    ["571", "Unova", "Zoroark", "ゾロアーク", "43782", "Zoroark", "Zoroark", "Zoroark", "Zoroark"],
    ["745", "Alola", "Lycanroc", "ルガルガン", "42792", "Lycanroc", "Lycanroc", "Lougaroc", "Wolwerock"],
    ["823", "Galar", "Corviknight", "アーマーガア", "41711", "Corviknight", "Corviknight", "Corvaillus", "Krarmor"],
    ["330", "Hoenn", "Flygon", "フライゴン", "41420", "Flygon", "Flygon", "Libégon", "Libelldra"],
    ["635", "Unova", "Hydreigon", "サザンドラ", "40054", "Hydreigon", "Hydreigon", "Trioxhydre", "Trikephalo"],
    ["254", "Hoenn", "Sceptile", "ジュカイン", "38724", "Sceptile", "Sceptile", "Jungko", "Gewaldro"],
    ["257", "Hoenn", "Blaziken", "バシャーモ", "38307", "Blaziken", "Blaziken", "Braségali", "Lohgock"],
    ["872", "Galar", "Snom", "ユキハミ", "38034", "Snom", "Snom", "Frissonille", "Snomnom"]
  ],
  "Kanto": [
    ["6", "Kanto", "Charizard", "リザードン", "93968", "Charizard", "Charizard", "Dracaufeu", "Glurak"],
    ["94", "Kanto", "Gengar", "ゲンガー", "60214", "Gengar", "Gengar", "Ectoplasma", "Gengar"],
    ["1", "Kanto", "Bulbasaur", "フシギダネ", "56015", "Bulbasaur", "Bulbasaur", "Bulbizarre", "Bisasam"],
    ["25", "Kanto", "Pikachu", "ピカチュウ", "48060", "Pikachu", "Pikachu", "Pikachu", "Pikachu"],
    ["133", "Kanto", "Eevee", "イーブイ", "47762", "Eevee", "Eevee", "Évoli ", "Evoli"],
    ["149", "Kanto", "Dragonite", "カイリュー", "36873", "Dragonite", "Dragonite", "Dracolosse", "Dragoran"],
    ["151", "Kanto", "Mew", "ミュウ", "36266", "Mew", "Mew", "Mew", "Mew"],
    ["150", "Kanto", "Mewtwo", "ミュウツー", "34585", "Mewtwo", "Mewtwo", "Mewtwo", "Mewtu"],
    ["59", "Kanto", "Arcanine", "ウインディ", "29795", "Arcanine", "Arcanine", "Arcanin", "Arkani"],
    ["131", "Kanto", "Lapras", "ラプラス", "23411", "Lapras", "Lapras", "Lokhlass", "Lapras"],
    ["143", "Kanto", "Snorlax", "カビゴン", "19768", "Snorlax", "Snorlax", "Ronflex", "Relaxo"],
    ["38", "Kanto", "Ninetales", "キュウコン", "19044", "Ninetales", "Ninetales", "Feunard", "Vulnona"],
    ["7", "Kanto", "Squirtle", "ゼニガメ", "18476", "Squirtle", "Squirtle", "Carapuce", "Schiggy"],
    ["9", "Kanto", "Blastoise", "カメックス", "16795", "Blastoise", "Blastoise", "Tortank", "Turtok"],
    ["134", "Kanto", "Vaporeon", "シャワーズ", "14887", "Vaporeon", "Vaporeon", "Aquali", "Aquana"],
    ["89", "Kanto", "Muk", "ベトベトン", "14358", "Muk", "Muk", "Grotadmorv", "Sleimok"],
    ["4", "Kanto", "Charmander", "ヒトカゲ", "14049", "Charmander", "Charmander", "Salamèche", "Glumanda"],
    ["132", "Kanto", "Ditto", "メタモン", "13843", "Ditto", "Ditto", "Métamorph", "Ditto"],
    ["79", "Kanto", "Slowpoke", "ヤドン", "12369", "Slowpoke", "Slowpoke", "Ramoloss", "Flegmon"],
    ["34", "Kanto", "Nidoking", "ニドキング", "11586", "Nidoking", "Nidoking", "Nidoking", "Nidoking"],
    ["137", "Kanto", "Porygon", "ポリゴン", "11311", "Porygon", "Porygon", "Porygon", "Porygon"],
    ["37", "Kanto", "Vulpix", "ロコン", "11224", "Vulpix", "Vulpix", "Goupix", "Vulpix"],
    ["54", "Kanto", "Psyduck", "コダック", "11212", "Psyduck", "Psyduck", "Psykokwak", "Enton"],
    ["26", "Kanto", "Raichu", "ライチュウ", "11196", "Raichu", "Raichu", "Raichu", "Raichu"],
    ["127", "Kanto", "Pinsir", "カイロス", "11162", "Pinsir", "Pinsir", "Scarabrute", "Pinsir"],
    ["135", "Kanto", "Jolteon", "サンダース", "11064", "Jolteon", "Jolteon", "Voltali", "Blitza"],
    ["148", "Kanto", "Dragonair", "ハクリュー", "11051", "Dragonair", "Dragonair", "Draco ", "Dragonir"],
    ["3", "Kanto", "Venusaur", "フシギバナ", "10454", "Venusaur", "Venusaur", "Florizarre", "Bisaflor"],
    ["144", "Kanto", "Articuno", "フリーザー", "10450", "Articuno", "Articuno", "Artikodin", "Arktos"],
    ["81", "Kanto", "Magnemite", "コイル", "9955", "Magnemite", "Magnemite", "Magnéti", "Magnetilo"]
  ],
  "Johto": [
    ["197", "Johto", "Umbreon", "ブラッキー", "67062", "Umbreon", "Umbreon", "Noctali", "Nachtara"],
    ["248", "Johto", "Tyranitar", "バンギラス", "56834", "Tyranitar", "Tyranitar", "Tyranocif", "Despotar"],
    ["249", "Johto", "Lugia", "ルギア", "53268", "Lugia", "Lugia", "Lugia", "Lugia"],
    ["157", "Johto", "Typhlosion", "バクフーン", "35184", "Typhlosion", "Typhlosion", "Typhlosion", "Tornupto"],
    ["212", "Johto", "Scizor", "ハッサム", "34691", "Scizor", "Scizor", "Cizayox", "Scherox"],
    ["181", "Johto", "Ampharos", "デンリュウ", "32009", "Ampharos", "Ampharos", "Pharamp", "Ampharos"],
    ["196", "Johto", "Espeon", "エーフィ", "30052", "Espeon", "Espeon", "Mentali", "Psiana"],
    ["155", "Johto", "Cyndaquil", "ヒノアラシ", "28332", "Cyndaquil", "Cyndaquil", "Héricendre", "Feurigel"],
    ["245", "Johto", "Suicune", "スイクン", "26277", "Suicune", "Suicune", "Suicune", "Suicune"],
    ["158", "Johto", "Totodile", "ワニノコ", "22526", "Totodile", "Totodile", "Kaiminus", "Karnimani"],
    ["169", "Johto", "Crobat", "クロバット", "21548", "Crobat", "Crobat", "Nostenfer", "Iksbat"],
    ["162", "Johto", "Furret", "オオタチ", "21447", "Furret", "Furret", "Fouinar", "Wiesenior"],
    ["251", "Johto", "Celebi", "セレビィ", "20492", "Celebi", "Celebi", "Celebi", "Celebi"],
    ["152", "Johto", "Chikorita", "チコリータ", "18521", "Chikorita", "Chikorita", "Germignon", "Endivie"],
    ["250", "Johto", "Ho-Oh", "ホウオウ", "18278", "Ho-Oh", "Ho-Oh", "Ho-Oh", "Ho-Oh"],
    ["160", "Johto", "Feraligatr", "オーダイル", "18245", "Feraligatr", "Feraligatr", "Aligatueur", "Impergator"],
    ["214", "Johto", "Heracross", "ヘラクロス", "16577", "Heracross", "Heracross", "Scarhino", "Skaraborn"],
    ["177", "Johto", "Natu", "ネイティ", "15699", "Natu", "Natu", "Natu", "Natu"],
    ["172", "Johto", "Pichu", "ピチュー", "15695", "Pichu", "Pichu", "Pichu", "Pichu"],
    ["229", "Johto", "Houndoom", "ヘルガー", "14742", "Houndoom", "Houndoom", "Démolosse", "Hundemon"],
    ["175", "Johto", "Togepi", "トゲピー", "14288", "Togepi", "Togepi", "Togepi", "Togepi"],
    ["195", "Johto", "Quagsire", "ヌオー", "13308", "Quagsire", "Quagsire", "Maraiste", "Morlord"],
    ["244", "Johto", "Entei", "エンテイ", "10404", "Entei", "Entei", "Entei", "Entei"],
    ["156", "Johto", "Quilava", "マグマラシ", "10032", "Quilava", "Quilava", "Feurisson", "Igelavar"],
    ["233", "Johto", "Porygon2", "ポリゴン２", "9902", "Porygon2", "Porygon2", "Porygon2", "Porygon2"],
    ["194", "Johto", "Wooper", "ウパー", "9323", "Wooper", "Wooper", "Axoloto", "Felino"],
    ["200", "Johto", "Misdreavus", "ムウマ", "8156", "Misdreavus", "Misdreavus", "Feuforêve", "Traunfugil"],
    ["213", "Johto", "Shuckle", "ツボツボ", "8129", "Shuckle", "Shuckle", "Caratroc", "Pottrott"],
    ["202", "Johto", "Wobbuffet", "ソーナンス", "7745", "Wobbuffet", "Wobbuffet", "Qulbutoké", "Woingenau"],
    ["230", "Johto", "Kingdra", "キングドラ", "7418", "Kingdra", "Kingdra", "Hyporoi", "Seedraking"]
  ],
  "Hoenn": [
    ["384", "Hoenn", "Rayquaza", "レックウザ", "60939", "Rayquaza", "Rayquaza", "Rayquaza", "Rayquaza"],
    ["282", "Hoenn", "Gardevoir", "サーナイト", "60596", "Gardevoir", "Gardevoir", "Gardevoir", "Guardevoir"],
    ["330", "Hoenn", "Flygon", "フライゴン", "41420", "Flygon", "Flygon", "Libégon", "Libelldra"],
    ["254", "Hoenn", "Sceptile", "ジュカイン", "38724", "Sceptile", "Sceptile", "Jungko", "Gewaldro"],
    ["257", "Hoenn", "Blaziken", "バシャーモ", "38307", "Blaziken", "Blaziken", "Braségali", "Lohgock"],
    ["258", "Hoenn", "Mudkip", "ミズゴロウ", "36920", "Mudkip", "Mudkip", "Gobou", "Hydropi"],
    ["376", "Hoenn", "Metagross", "メタグロス", "35631", "Blaziken", "Blaziken", "Braségali", "Lohgock"],
    ["385", "Hoenn", "Jirachi", "ジラーチ", "29611", "Jirachi", "Jirachi", "Jirachi", "Jirachi"],
    ["350", "Hoenn", "Milotic", "ミロカロス", "28295", "Milotic", "Milotic", "Milobellus", "Milotic"],
    ["359", "Hoenn", "Absol", "アブソル", "27781", "Absol", "Absol", "Absol", "Absol"],
    ["260", "Hoenn", "Swampert", "ラグラージ", "26540", "Swampert", "Swampert", "Laggron", "Sumpex"],
    ["373", "Hoenn", "Salamence", "ボーマンダ", "24920", "Salamence", "Salamence", "Drattak", "Brutalanda"],
    ["304", "Hoenn", "Aron", "ココドラ", "24389", "Aron", "Aron", "Galekid", "Stollunior"],
    ["306", "Hoenn", "Aggron", "ボスゴドラ", "18120", "Aggron", "Aggron", "Galeking", "Stolloss"],
    ["380", "Hoenn", "Latias", "ラティアス", "17478", "Latias", "Latias", "Latias", "Latias"],
    ["334", "Hoenn", "Altaria", "チルタリス", "16814", "Altaria", "Altaria", "Altaria", "Altaria"],
    ["382", "Hoenn", "Kyogre", "カイオーガ", "15585", "Kyogre", "Kyogre", "Kyogre", "Kyogre"],
    ["303", "Hoenn", "Mawile", "クチート", "15523", "Mawile", "Mawile", "Mysdibule", "Flunkifer"],
    ["286", "Hoenn", "Breloom", "キノガッサ", "12801", "Breloom", "Breloom", "Chapignon", "Kapilz"],
    ["255", "Hoenn", "Torchic", "アチャモ", "12568", "Torchic", "Torchic", "Poussifeu", "Flemmli"],
    ["381", "Hoenn", "Latios", "ラティオス", "12487", "Latios", "Latios", "Latios", "Latios"],
    ["383", "Hoenn", "Groudon", "グラードン", "11982", "Groudon", "Groudon", "Groudon", "Groudon"],
    ["386", "Hoenn", "Deoxys", "デオキシス", "10842", "Deoxys", "Deoxys", "Deoxys", "Deoxys"],
    ["253", "Hoenn", "Grovyle", "ジュプトル", "10746", "Grovyle", "Grovyle", "Massko", "Reptain"],
    ["354", "Hoenn", "Banette", "ジュペッタ", "10579", "Banette", "Banette", "Branette", "Banette"],
    ["252", "Hoenn", "Treecko", "キモリ", "9339", "Treecko", "Treecko", "Arcko", "Geckarbor"],
    ["302", "Hoenn", "Sableye", "ヤミラミ", "9012", "Sableye", "Sableye", "Ténéfix", "Zobiris"],
    ["272", "Hoenn", "Ludicolo", "ルンパッパ", "8708", "Ludicolo", "Ludicolo", "Ludicolo", "Kappalores"],
    ["292", "Hoenn", "Shedinja", "ヌケニン", "6703", "Shedinja", "Shedinja", "Munja", "Ninjatom"],
    ["262", "Hoenn", "Mightyena", "グラエナ", "6459", "Mightyena", "Mightyena", "Grahyèna", "Magnayen"]
  ],
  "Sinnoh": [
    ["448", "Sinnoh", "Lucario", "ルカリオ", "102259", "Lucario", "Lucario", "Lucario", "Lucario"],
    ["445", "Sinnoh", "Garchomp", "ガブリアス", "61877", "Garchomp", "Garchomp", "Carchacrok", "Knakrack"],
    ["405", "Sinnoh", "Luxray", "レントラー", "46032", "Luxray", "Luxray", "Luxray", "Luxtra"],
    ["393", "Sinnoh", "Piplup", "ポッチャマ", "34680", "Piplup", "Piplup", "Tiplouf", "Plinfa"],
    ["392", "Sinnoh", "Infernape", "ゴウカザル", "33267", "Infernape", "Infernape", "Simiabraz", "Panferno"],
    ["491", "Sinnoh", "Darkrai", "ダークライ", "30544", "Darkrai", "Darkrai", "Darkrai", "Darkrai"],
    ["471", "Sinnoh", "Glaceon", "グレイシア", "26161", "Glaceon", "Glaceon", "Givrali", "Glaziola"],
    ["493", "Sinnoh", "Arceus", "アルセウス", "24502", "Arceus", "Arceus", "Arceus", "Arceus"],
    ["395", "Sinnoh", "Empoleon", "エンペルト", "21773", "Empoleon", "Empoleon", "Pingoléon", "Impoleon"],
    ["487", "Sinnoh", "Giratina", "ギラティナ", "21366", "Giratina", "Giratina", "Giratina", "Giratina"],
    ["470", "Sinnoh", "Leafeon", "リーフィア", "20859", "Leafeon", "Leafeon", "Phyllali", "Folipurba"],
    ["389", "Sinnoh", "Torterra", "ドダイトス", "20632", "Torterra", "Torterra", "Torterra", "Chelterrar"],
    ["492", "Sinnoh", "Shaymin", "シェイミ", "17465", "Shaymin", "Shaymin", "Shaymin", "Shaymin"],
    ["483", "Sinnoh", "Dialga", "ディアルガ", "14292", "Dialga", "Dialga", "Dialga", "Dialga"],
    ["475", "Sinnoh", "Gallade", "エルレイド", "14144", "Gallade", "Gallade", "Gallame", "Glagladi"],
    ["398", "Sinnoh", "Staraptor", "ムクホーク", "14054", "Staraptor", "Staraptor", "Étouraptor", "Staraptor"],
    ["468", "Sinnoh", "Togekiss", "トゲキッス", "13426", "Togekiss", "Togekiss", "Togekiss", "Togekiss"],
    ["472", "Sinnoh", "Gliscor", "グライオン", "12676", "Gliscor", "Gliscor", "Scorvol", "Skorgro"],
    ["428", "Sinnoh", "Lopunny", "ミミロップ", "11411", "Lopunny", "Lopunny", "Lockpin", "Schlapor"],
    ["399", "Sinnoh", "Bidoof", "ビッパ", "10924", "Bidoof", "Bidoof", "Keunotor", "Bidiza"],
    ["387", "Sinnoh", "Turtwig", "ナエトル", "10865", "Turtwig", "Turtwig", "Tortipouss", "Chelast"],
    ["461", "Sinnoh", "Weavile", "マニューラ", "10854", "Weavile", "Weavile", "Dimoret", "Snibunna"],
    ["478", "Sinnoh", "Froslass", "ユキメノコ", "10408", "Froslass", "Froslass", "Momartik", "Frosdedje"],
    ["417", "Sinnoh", "Pachirisu", "パチリス", "9694", "Pachirisu", "Pachirisu", "Pachirisu", "Pachirisu"],
    ["479", "Sinnoh", "Rotom", "ロトム", "9417", "Rotom", "Rotom", "Motisma", "Rotom"],
    ["407", "Sinnoh", "Roserade", "ロズレイド", "9092", "Roserade", "Roserade", "Roserade", "Roserade"],
    ["429", "Sinnoh", "Mismagius", "ムウマージ", "8833", "Mismagius", "Mismagius", "Magirêve", "Traunmagil"],
    ["403", "Sinnoh", "Shinx", "コリンク", "8373", "Shinx", "Shinx", "Lixy", "Sheinux"],
    ["446", "Sinnoh", "Munchlax", "ゴンベ", "7289", "Munchlax", "Munchlax", "Goinfrex", "Mampfaxo"],
    ["474", "Sinnoh", "Porygon-Z", "ポリゴンＺ", "7040", "Porygon-Z", "Porygon-Z", "Porygon-Z", "Porygon-Z"]
  ],
  "Unova": [
    ["609", "Unova", "Chandelure", "シャンデラ", "50943", "Chandelure", "Chandelure", "Lugulabre", "Skelabra"],
    ["571", "Unova", "Zoroark", "ゾロアーク", "43782", "Zoroark", "Zoroark", "Zoroark", "Zoroark"],
    ["635", "Unova", "Hydreigon", "サザンドラ", "40054", "Hydreigon", "Hydreigon", "Trioxhydre", "Trikephalo"],
    ["637", "Unova", "Volcarona", "ウルガモス", "24389", "Volcarona", "Volcarona", "Pyrax", "Ramoth"],
    ["612", "Unova", "Haxorus", "オノノクス", "22937", "Haxorus", "Haxorus", "Tranchodon", "Maxax"],
    ["497", "Unova", "Serperior", "ジャローダ", "22269", "Serperior", "Serperior", "Majaspic", "Serpiroyal"],
    ["501", "Unova", "Oshawott", "ミジュマル", "21990", "Oshawott", "Oshawott", "Moustillon", "Ottaro"],
    ["644", "Unova", "Zekrom", "ゼクロム", "21477", "Zekrom", "Zekrom", "Zekrom", "Zekrom"],
    ["494", "Unova", "Victini", "ビクティニ", "20957", "Victini", "Victini", "Victini", "Victini"],
    ["643", "Unova", "Reshiram", "レシラム", "20123", "Reshiram", "Reshiram", "Reshiram", "Reshiram"],
    ["553", "Unova", "Krookodile", "ワルビアル", "19628", "Krookodile", "Krookodile", "Crocorible", "Rabigator"],
    ["547", "Unova", "Whimsicott", "エルフーン", "17855", "Whimsicott", "Whimsicott", "Farfaduvet", "Elfun"],
    ["495", "Unova", "Snivy", "ツタージャ", "17020", "Snivy", "Snivy", "Vipélierre", "Serpifeu"],
    ["570", "Unova", "Zorua", "ゾロア", "14910", "Zorua", "Zorua", "Zorua", "Zorua"],
    ["545", "Unova", "Scolipede", "ペンドラー", "14536", "Scolipede", "Scolipede", "Brutapode", "Cerapendra"],
    ["579", "Unova", "Reuniclus", "ランクルス", "14129", "Reuniclus", "Reuniclus", "Symbios", "Zytomega"],
    ["648", "Unova", "Meloetta", "メロエッタ", "13915", "Meloetta", "Meloetta", "Meloetta", "Meloetta"],
    ["503", "Unova", "Samurott", "ダイケンキ", "11444", "Samurott", "Samurott", "Clamiral", "Admurai"],
    ["530", "Unova", "Excadrill", "ドリュウズ", "11376", "Excadrill", "Excadrill", "Minotaupe", "Stalobor"],
    ["549", "Unova", "Lilligant", "ドレディア", "11292", "Lilligant", "Lilligant", "Fragilady", "Dressella"],
    ["607", "Unova", "Litwick", "ヒトモシ", "11140", "Litwick", "Litwick", "Funécire", "Lichtel"],
    ["625", "Unova", "Bisharp", "キリキザン", "10975", "Bisharp", "Bisharp", "Scalproie", "Caesurio"],
    ["646", "Unova", "Kyurem", "キュレム", "10338", "Kyurem", "Kyurem", "Kyurem", "Kyurem"],
    ["573", "Unova", "Cinccino", "チラチーノ", "9892", "Cinccino", "Cinccino", "Pashmilla", "Chillabell"],
    ["587", "Unova", "Emolga", "エモンガ", "9798", "Emolga", "Emolga", "Emolga", "Emolga"],
    ["647", "Unova", "Keldeo", "ケルディオ", "8915", "Keldeo", "Keldeo", "Keldeo", "Keldeo"],
    ["595", "Unova", "Joltik", "バチュル", "8796", "Joltik", "Joltik", "Statitik", "Wattzapf"],
    ["572", "Unova", "Minccino", "チラーミィ", "8739", "Minccino", "Minccino", "Chinchidou", "Picochilla"],
    ["623", "Unova", "Golurk", "ゴルーグ", "8035", "Golurk", "Golurk", "Golemastoc", "Golgantes"],
    ["649", "Unova", "Genesect", "ゲノセクト", "7905", "Genesect", "Genesect", "Genesect", "Genesect"]
  ],
  "Kalos": [
    ["658", "Kalos", "Greninja", "ゲッコウガ", "140559", "Greninja", "Greninja", "Amphinobi", "Quajutsu"],
    ["700", "Kalos", "Sylveon", "ニンフィア", "66029", "Sylveon", "Sylveon", "Nymphali", "Feelinara"],
    ["681", "Kalos", "Aegislash", "ギルガルド", "51517", "Aegislash", "Aegislash", "Exagide", "Durengard"],
    ["715", "Kalos", "Noivern", "オンバーン", "34795", "Noivern", "Noivern", "Bruyverne", "UHaFnir"],
    ["706", "Kalos", "Goodra", "ヌメルゴン", "30209", "Goodra", "Goodra", "Muplodocus", "Viscogon"],
    ["663", "Kalos", "Talonflame", "ファイアロー", "22328", "Talonflame", "Talonflame", "Flambusard", "Fiaro"],
    ["702", "Kalos", "Dedenne", "デデンネ", "21691", "Dedenne", "Dedenne", "DEDENNE", "DEDENNE"],
    ["717", "Kalos", "Yveltal", "イベルタル", "20852", "Yveltal", "Yveltal", "Yveltal", "Yveltal"],
    ["704", "Kalos", "Goomy", "ヌメラ", "20299", "Goomy", "Goomy", "Mucuscule", "Viscora"],
    ["697", "Kalos", "Tyrantrum", "ガチゴラス", "18778", "Tyrantrum", "Tyrantrum", "Rexillius", "Monargoras"],
    ["716", "Kalos", "Xerneas", "ゼルネアス", "17415", "Xerneas", "Xerneas", "Xerneas", "Xerneas"],
    ["701", "Kalos", "Hawlucha", "ルチャブル", "14607", "Hawlucha", "Hawlucha", "Brutalibré ", "Resladero"],
    ["678", "Kalos", "Meowstic", "ニャオニクス", "13661", "Meowstic", "Meowstic", "Mistigrix", "Psiaugon"],
    ["653", "Kalos", "Fennekin", "フォッコ", "13508", "Fennekin", "Fennekin", "Feunnec", "Fynx"],
    ["718", "Kalos", "Zygarde", "ジガルデ", "11943", "Zygarde", "Zygarde", "Zygarde", "Zygarde"],
    ["719", "Kalos", "Diancie", "ディアンシー", "10918", "Diancie", "Diancie", "Diancie", "Diancie"],
    ["655", "Kalos", "Delphox", "マフォクシー", "10889", "Delphox", "Delphox", "Goupelin", "Fennexis"],
    ["654", "Kalos", "Braixen", "テールナー", "10807", "Braixen", "Braixen", "Roussil", "Rutena"],
    ["677", "Kalos", "Espurr", "ニャスパー", "10402", "Espurr", "Espurr", "Psystigri", "Psiau "],
    ["720", "Kalos", "Hoopa", "フーパ", "10327", "Hoopa", "Hoopa", "Hoopa", "Hoopa"],
    ["699", "Kalos", "Aurorus", "アマルルガ", "10321", "Aurorus", "Aurorus", "Dragmara", "Amagarga"],
    ["674", "Kalos", "Pancham", "ヤンチャム", "10187", "Pancham", "Pancham", "Pandespiègle", "Pam-Pam"],
    ["691", "Kalos", "Dragalge", "ドラミドロ", "9882", "Dragalge", "Dragalge", "Kravarech", "Tandrak"],
    ["710", "Kalos", "Pumpkaboo", "バケッチャ", "9162", "Pumpkaboo", "Pumpkaboo", "Pitrouille", "Irrbis"],
    ["709", "Kalos", "Trevenant", "オーロット", "9019", "Trevenant", "Trevenant", "Desséliande", "Trombork"],
    ["675", "Kalos", "Pangoro", "ゴロンダ", "8859", "Pangoro", "Pangoro", "Pandarbare", "Pandagro"],
    ["708", "Kalos", "Phantump", "ボクレー", "8231", "Phantump", "Phantump", "Brocélôme", "Paragoni"],
    ["687", "Kalos", "Malamar", "カラマネロ", "6503", "Malamar", "Malamar", "Sepiatroce", "Calamanero"],
    ["695", "Kalos", "Heliolisk", "エレザード", "6386", "Heliolisk", "Heliolisk", "Iguolta", "Elezard"],
    ["656", "Kalos", "Froakie", "ケロマツ", "6293", "Froakie", "Froakie", "Grenousse", "Froxy"]
  ],
  "Alola": [
    ["778", "Alola", "Mimikyu", "ミミッキュ", "99077", "Mimikyu", "Mimikyu", "Mimiqui", "Mimigma"],
    ["722", "Alola", "Rowlet", "モクロー", "52367", "Rowlet", "Rowlet", "Brindibou", "Bauz"],
    ["724", "Alola", "Decidueye", "ジュナイパー", "44011", "Decidueye", "Decidueye", "Archéduc", "Silvarro"],
    ["745", "Alola", "Lycanroc", "ルガルガン", "42792", "Lycanroc", "Lycanroc", "Lougaroc", "Wolwerock"],
    ["807", "Alola", "Zeraora", "ゼラオラ", "31691", "Zeraora", "Zeraora", "Zeraora", "Zeraora"],
    ["727", "Alola", "Incineroar", "ガオガエン", "29925", "Incineroar", "Incineroar", "Félinferno", "Fuegro"],
    ["768", "Alola", "Golisopod", "グソクムシャ", "26975", "Golisopod", "Golisopod", "Sarmuraï", "Tectass"],
    ["730", "Alola", "Primarina", "アシレーヌ", "25953", "Primarina", "Primarina", "Oratoria", "Primarene"],
    ["760", "Alola", "Bewear", "キテルグマ", "17181", "Bewear", "Bewear", "Chelours", "Kosturso"],
    ["791", "Alola", "Solgaleo", "ソルガレオ", "16274", "Solgaleo", "Solgaleo", "Solgaleo", "Solgaleo"],
    ["794", "Alola", "Buzzwole", "マッシブーン", "14747", "Buzzwole", "Buzzwole", "Mouscoto", "Masskito"],
    ["771", "Alola", "Pyukumuku", "ナマコブシ", "14358", "Pyukumuku", "Pyukumuku", "Concombaffe", "Gufa"],
    ["725", "Alola", "Litten", "ニャビー", "14005", "Litten", "Litten", "Flamiaou", "Flamiau"],
    ["773", "Alola", "Silvally", "シルヴァディ", "13897", "Silvally", "Silvally", "Silvallié", "Amigento"],
    ["738", "Alola", "Vikavolt", "クワガノン", "13375", "Vikavolt", "Vikavolt", "Lucanon", "Donarion"],
    ["758", "Alola", "Salazzle", "エンニュート", "12863", "Salazzle", "Salazzle", "Malamandre", "Amfira"],
    ["784", "Alola", "Kommo-o", "ジャラランガ", "12790", "Kommo-o", "Kommo-o", "Ékaïser", "Grandiras"],
    ["802", "Alola", "Marshadow", "マーシャドー", "12107", "Marshadow", "Marshadow", "Marshadow", "Marshadow"],
    ["744", "Alola", "Rockruff", "イワンコ", "10986", "Rockruff", "Rockruff", "Rocabot", "Wuffels"],
    ["763", "Alola", "Tsareena", "アマージョ", "10900", "Tsareena", "Tsareena", "Sucreine", "Fruyal"],
    ["792", "Alola", "Lunala", "ルナアーラ", "9887", "Lunala", "Lunala", "Lunala", "Lunala"],
    ["754", "Alola", "Lurantis", "ラランテス", "8889", "Lurantis", "Lurantis", "Floramantis", "Mantidea"],
    ["777", "Alola", "Togedemaru", "トゲデマル", "8531", "Togedemaru", "Togedemaru", "Togedemaru", "Togedemaru"],
    ["748", "Alola", "Toxapex", "ドヒドイデ", "8221", "Toxapex", "Toxapex", "Prédastérie", "Aggrostella"],
    ["785", "Alola", "Tapu Koko", "カプ・コケコ", "8090", "Tapu Koko", "Tapu Koko", "Tokorico", "Kapu-Riki"],
    ["750", "Alola", "Mudsdale", "バンバドロ", "7787", "Mudsdale", "Mudsdale", "Bourrinos", "Pampross"],
    ["800", "Alola", "Necrozma", "ネクロズマ", "7739", "Necrozma", "Necrozma", "Necrozma", "Necrozma"],
    ["728", "Alola", "Popplio", "アシマリ", "7542", "Popplio", "Popplio", "Otaquin", "Robball"],
    ["789", "Alola", "Cosmog", "コスモッグ", "7515", "Cosmog", "Cosmog", "Cosmog", "Cosmog"],
    ["743", "Alola", "Ribombee", "アブリボン", "7126", "Ribombee", "Ribombee", "Rubombelle", "Bandelby"]
  ],
  "Galar": [
    ["887", "Galar", "Dragapult", "ドラパルト", "57973", "Dragapult", "Dragapult", "Lanssorien", "Katapuldra"],
    ["849", "Galar", "Toxtricity", "ストリンダー", "55032", "Toxtricity", "Toxtricity", "Salarsen", "Riffex"],
    ["823", "Galar", "Corviknight", "アーマーガア", "41711", "Corviknight", "Corviknight", "Corvaillus", "Krarmor"],
    ["872", "Galar", "Snom", "ユキハミ", "38034", "Snom", "Snom", "Frissonille", "Snomnom"],
    ["869", "Galar", "Alcremie", "マホイップ", "30612", "Alcremie", "Alcremie", "Charmilly", "Pokusan"],
    ["815", "Galar", "Cinderace", "エースバーン", "26892", "Cinderace", "Cinderace", "Pyobut", "Liberlo"],
    ["888", "Galar", "Zacian", "ザシアン", "26158", "Zacian", "Zacian", "Zacian", "Zacian"],
    ["835", "Galar", "Yamper", "ワンパチ", "25695", "Yamper", "Yamper", "Voltoutou", "Voldi"],
    ["831", "Galar", "Wooloo", "ウールー", "21266", "Wooloo", "Wooloo", "Moumouton", "Wolly"],
    ["818", "Galar", "Inteleon", "インテレオン", "20697", "Inteleon", "Inteleon", "Lézargus", "Intelleon"],
    ["865", "Galar", "Sirfetch'd", "ネギガナイト", "20217", "Sirfetch'd", "Sirfetch'd", "Palarticho", "Lauchzelot"],
    ["813", "Galar", "Scorbunny", "ヒバニー", "20058", "Scorbunny", "Scorbunny", "Flambino", "Hopplo"],
    ["858", "Galar", "Hatterene", "ブリムオン", "18581", "Hatterene", "Hatterene", "Sorcilence", "Silembrim"],
    ["816", "Galar", "Sobble", "メッソン", "17155", "Sobble", "Sobble", "Larméléon", "Memmeon"],
    ["808", "Galar", "Meltan", "メルタン", "16077", "Meltan", "Meltan", "Meltan", "Meltan"],
    ["870", "Galar", "Falinks", "タイレーツ", "16009", "Falinks", "Falinks", "Hexadron", "Legios"],
    ["842", "Galar", "Appletun", "タルップル", "15989", "Appletun", "Appletun", "Dratatin", "Schlapfel"],
    ["890", "Galar", "Eternatus", "ムゲンダイナ", "15699", "Eternatus", "Eternatus", "Eternatus", "Éthernatos"],
    ["877", "Galar", "Morpeko", "モルペコ", "13945", "Morpeko", "Morpeko", "Morpeko", "Morpeko"],
    ["810", "Galar", "Grookey", "サルノリ", "13478", "Grookey", "Grookey", "Ouistempo", "Chimpep"],
    ["861", "Galar", "Grimmsnarl", "オーロンゲ", "12923", "Grimmsnarl", "Grimmsnarl", "Angoliath", "Olangaar"],
    ["889", "Galar", "Zamazenta", "ザマゼンタ", "12641", "Zamazenta", "Zamazenta", "Zamazenta", "Zamazenta"],
    ["809", "Galar", "Melmetal", "メルメタル", "12356", "Melmetal", "Melmetal", "Melmetal", "Melmetal"],
    ["851", "Galar", "Centiskorch", "マルヤクデ", "11619", "Centiskorch", "Centiskorch", "Scolocendre", "Infernopod"],
    ["882", "Galar", "Dracovish", "ウオノラゴン", "11436", "Dracovish", "Dracovish", "Hydragon", "Pescragon"],
    ["873", "Galar", "Frosmoth", "モスノウ", "10988", "Frosmoth", "Frosmoth", "Beldeneige", "Mottineva"],
    ["875", "Galar", "Eiscue", "コオリッポ", "10392", "Eiscue", "Eiscue", "Bekaglaçon", "Kubuin"],
    ["862", "Galar", "Obstagoon", "タチフサグマ", "8705", "Obstagoon", "Obstagoon", "Ixon", "Barrikadax"],
    ["812", "Galar", "Rillaboom", "ゴリランダー", "8625", "Rillaboom", "Rillaboom", "Gorythmic", "Gortrom"],
    ["845", "Galar", "Cramorant", "ウッウ", "8436", "Cramorant", "Cramorant", "Nigosier", "Urgl"]
  ]
};

export default POKEMON_DATA;
