
class PokemonRegionSelector {
  constructor() {

    let selector = document.getElementById('pokemon-region');
    let pokemonRegionList = document.getElementById('pokemon-region-list');

    selector.addEventListener('change',  (e) => {
      let val = e.target.value;
      this.onUpdate( val );
    });

  }

}

export default PokemonRegionSelector;
