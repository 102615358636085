import * as PIXI from 'pixi.js';
import gsap from "gsap";
import { TweenMax, TimelineMax, CSSPlugin, Elastic, Power2 }  from "gsap/all";
import PixiPlugin from "gsap/PixiPlugin";
import config from "../config";
import each from 'lodash/each';
import DataManager from "./data-manager";
import {
  qs,
  qsArray
} from "../utilities";
// https://bitbucket.pokemon.tools/scm/ms/pokemonday2020.git
const {
  POKE_REGION_KEYS,
  ROTATION_COUNT,
  CIRC_TIMELINE_DURATION
} = config;

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [ CSSPlugin, PixiPlugin ];

let dataManager;

const poke5 = 4;
const poke4 = 3;
const poke3 = 2;
const poke2 = 1;
const poke1 = 0;

const whole = CIRC_TIMELINE_DURATION / 1000;
const half = CIRC_TIMELINE_DURATION / 2 / 1000;
const third = CIRC_TIMELINE_DURATION / 3 / 1000;
const fourth = CIRC_TIMELINE_DURATION / 4 / 1000;
const fifth = CIRC_TIMELINE_DURATION / 5 / 1000;
const sixth = CIRC_TIMELINE_DURATION / 6 / 1000;
const eighth = CIRC_TIMELINE_DURATION / 8 / 1000;
const tenth = CIRC_TIMELINE_DURATION / 10 / 1000;

class HeroTimeline {
  constructor( entities ) {

    gsap.registerPlugin(PixiPlugin);
    PixiPlugin.registerPIXI(PIXI);

    dataManager = new DataManager();

    let heroTl = this;
    let parent = document.getElementById('hero');
    let heroText = qs('.hero-text', parent);
    let headers = qsArray('.region-header', parent);
    let headersAnim = qs('.hero-region-headers .animation-wrap', parent);
    let votes = qsArray('.vote', parent);
    let votesText = qsArray('.vote-text', parent);
    let votesAnim = qs('.hero-votes .animation-wrap', parent);
    let countdown = qsArray('.hero-count', parent);
    let countdownAnim = qs('.hero-countdown .animation-wrap', parent);
    let results = qsArray('.result', parent);
    let resultsName = qsArray('.result-name', parent);
    let resultsCount = qsArray('.result-count b', parent);
    let resultsAnim = qs('.hero-results .animation-wrap', parent);

    heroTl.entities = entities;

    heroTl.elements = {
      parent,
      heroText,
      headers,
      votes,
      votesText,
      countdown,
      results,
      resultsName,
      resultsCount
    };

    heroTl.animElements = {
      headersAnim,
      votesAnim,
      countdownAnim,
      resultsAnim
    };

    heroTl.createTimelines();

    let {
      cursorHand,
      cursorFlash
    } = heroTl.entities;

    let cursorTl = new TimelineMax({ repeat: -1 });
    cursorTl.to(cursorHand, 0.1, { pixi: { alpha: 0 } });
    cursorTl.to(cursorFlash, 0.1, { pixi: { alpha: 0 } });
    cursorTl.to(cursorHand, 0.1, { pixi: { alpha: 1 } });
    cursorTl.to(cursorHand, 0.5, { pixi: { alpha: 1 } });
    cursorTl.to(cursorFlash, 0, { pixi: { alpha: 1 } });
    cursorTl.to(cursorFlash, 0.5, { pixi: { alpha: 1 } });
    cursorTl.to(cursorFlash, 0, { pixi: { alpha: 0 } });
    cursorTl.to(cursorFlash, 0.5, { pixi: { alpha: 0 } });
    cursorTl.to(cursorFlash, 0, { pixi: { alpha: 1 } });
    cursorTl.to(cursorFlash, 0.5, { pixi: { alpha: 1 } });
    cursorTl.to(cursorFlash, 0, { pixi: { alpha: 0 } });
    cursorTl.to(cursorFlash, 0.5, { pixi: { alpha: 0 } });
    cursorTl.to(cursorFlash, 0, { pixi: { alpha: 1 } });
    // cursorTl.to(cursorFlash, 0.5, { pixi: { alpha: 1 } });
    cursorTl.to([cursorHand, cursorFlash], 1, { pixi: { x: -340, y: 280 } });
    cursorTl.to([cursorHand, cursorFlash], 1, { pixi: { x: -340, y: 280 } });

    cursorTl.pause();

    heroTl.cursorTl = cursorTl;

    // console.log('Constructor', this);
  }
  playIntro () {
    let heroTl = this;
    let { container, graphics, handle, pokeball, arrow, arrowLight, track, peach, pokeSprites, startMarker } = heroTl.entities;

    each(pokeSprites, sprite => {
      TweenMax.to(sprite, 0, { pixi: { colorize:"black", colorizeAmount: 1 } });
    });

    TweenMax.to(track, 1, {
      pixi: { alpha: 1 },
      ease: Power2
    });

    TweenMax.to([handle, pokeball, arrow, arrowLight, startMarker, peach], 0.25, {
      pixi: { alpha: 1 },
      ease: Power2
    });
    // TweenMax.to([handle, pokeball, arrow, arrowLight], 3, {
    //   pixi: { scaleX: 1, scaleY: 1 },
    //   ease: Elastic.easeOut.config(1, 0.3)
    // });

  }
  playHandleCTA () {
    let heroTl = this;
    let { container, graphics, handle, pokeball, arrow, arrowLight, track, peach, pokeSprites, startMarker } = heroTl.entities;

    if (heroTl.handleCTAPlaying) {
      return
    }
    heroTl.handleCTAPlaying = true;
    if (heroTl.handleCTATween) {
      heroTl.handleCTATween.kill();
    }
    heroTl.handleCTATween = TweenMax.to([handle, pokeball, arrow, arrowLight], 1, {
      pixi: { scaleX: 0.8, scaleY: 0.8 },
      ease: Power2.easeInOut,
      repeat: -1,
      yoyo: true
    });

    heroTl.cursorTl.play(0);

  }
  stopHandleCTA () {
    let heroTl = this;
    let { container, graphics, handle, pokeball, arrow, arrowLight, track, peach, pokeSprites, startMarker } = heroTl.entities;

    if (!heroTl.handleCTAPlaying) {
      return
    }
    heroTl.handleCTATween.kill();
    heroTl.handleCTAPlaying = false;
    heroTl.handleCTATween = TweenMax.to([handle, pokeball, arrow, arrowLight], 0.2, {
      pixi: { scaleX: 1, scaleY: 1 }
    });

    heroTl.cursorTl.pause();
    heroTl.cursorTl.progress(0);

  }
  hideTrack () {
    let heroTl = this;
    let { heroText } = heroTl.elements;
    let { container, graphics, handle, pokeball, track, peach, pokeSprites, startMarker, arrow, arrowLight } = heroTl.entities;

    TweenMax.to([track, peach, startMarker, handle, pokeball, arrow, arrowLight], 0.5, { alpha: 0 });

    heroTl.trackHidden = true;

  }
  showTrack () {
    let heroTl = this;
    let { heroText } = heroTl.elements;
    let { container, graphics, handle, pokeball, track, peach, pokeSprites, startMarker, arrow, arrowLight } = heroTl.entities;

    if (!heroTl.trackHidden) {
      return;
    }

    heroTl.trackHidden = false;

    TweenMax.to([track, peach, startMarker, handle, pokeball, arrow, arrowLight], 0.5, { alpha: 1 });

  }
  setRegion ( region ) {
    let heroTl = this;
    let {
      headers,
      votes,
      votesText,
      countdown,
      results,
      resultsName,
      resultsCount
    } = heroTl.elements;

    let regionData = dataManager.getTopFiveObjects(region);

    // set active the region-header using the data-region attribute
    each(headers, h => {
      h.style.opacity = 0;
    });
    let regionIndex = POKE_REGION_KEYS.indexOf(region);
    headers[regionIndex].style.opacity = 1;

    // populate the region data elements
    each(regionData, (data, i) => {
      votesText[i].innerText = data.votes;
      resultsName[i].innerText = data.localized;
      resultsCount[i].innerText = data.votes;
    });
    each(votes, v => {
      v.style.opacity = 0;
    });
    each(countdown, c => {
      c.style.opacity = 0;
    });
    each(results, r => {
      r.style.opacity = 0;
    });
    votes[poke5].style.opacity = 1;
    countdown[poke5].style.opacity = 1;
    results[poke5].style.opacity = 1;

    heroTl.setProgress( 0 );
  }
  createTimelines () {
    let heroTl = this;
    let {
      headers,
      votes,
      votesText,
      countdown,
      results,
      resultsName,
      resultsCount
    } = heroTl.elements;
    let {
      headersAnim,
      votesAnim,
      countdownAnim,
      resultsAnim
    } = heroTl.animElements;
    let {
      pokeSprites,
      pokeShadowSprites,
      yellowDiskContainer,
      yellowDisk1,
      yellowDisk2,
      whiteDiskContainer,
      whiteDisk1,
      one
    } = heroTl.entities;
    let ps = pokeSprites;
    let pss = pokeShadowSprites;

    // yoyo tweens
    yellowDiskContainer.scale.x = 0.9;
    yellowDiskContainer.scale.y = 0.9;
    TweenMax.to(yellowDiskContainer.scale, 2, { x: 1, y: 1, ease: Power2.easeInOut, repeat: -1, yoyo: true });
    TweenMax.to(whiteDiskContainer.scale, 2, { x: 0.9, y: 0.9, ease: Power2.easeInOut, repeat: -1, yoyo: true });
    yellowDisk1.alpha = 0;
    yellowDisk2.alpha = 0;
    whiteDisk1.alpha = 0;

    // create multiple timelines for the different elements / entities to simplify things
    // timeline single rotation duration should 3000ms so
    // we can break animation points up into thirds 1000ms
    let headerTl = new TimelineMax();
    let votesTl = new TimelineMax();
    let countdownTl = new TimelineMax();
    let resultsTl = new TimelineMax();
    let pokeTl = new TimelineMax();
    let pokeShadowTl = new TimelineMax();
    let yellow1Tl = new TimelineMax();
    let yellow2Tl = new TimelineMax();
    let white1Tl = new TimelineMax();
    let oneTl = new TimelineMax();

    // Initialize
    headerTl.to(headersAnim, 0, { opacity: 0 });
    votesTl.to(votesAnim, 0, { opacity: 0 });
    resultsTl.to(resultsAnim, 0, { opacity: 0 });
    countdownTl.to(countdownAnim, 0, { opacity: 0 });
    yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0.25, scaleX: 0.25, scaleY: 0.25 } });
    yellow2Tl.to(yellowDisk2, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    oneTl.to(whiteDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });

    // Pokemon #5 Start
    // header
    // console.log(tenth + tenth + (whole - tenth * 2));
    headerTl.to(headersAnim, tenth, {opacity: 1});
    headerTl.to(headersAnim, tenth, {opacity: 0});
    headerTl.to(headersAnim, (whole - tenth * 2), {opacity: 0});
    // header
    oneTl.to(one, whole, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // yellow1
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.25, scaleX: 0.25, scaleY: 0.25 } });
    // white1
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    // yellow 2
    yellow2Tl.to(yellowDisk2, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow2Tl.to(yellowDisk2, (whole - (half + tenth * 3)), { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // votes
    // console.log(fifth + tenth * 3  + (whole - (fourth + tenth * 3)));
    votesTl.to(votesAnim, fifth, { opacity: 0 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 0 });
    votesTl.to(votesAnim, (whole - (fifth + tenth * 3)), { opacity: 0 });
    // countdown
    // console.log(half + tenth * 3  + (whole - (half + tenth * 3)));
    countdownTl.to(countdownAnim, half, { opacity: 0 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 0 });
    countdownTl.to(countdownAnim, (whole - (half + tenth * 3)), { opacity: 0 });
    // results
    // console.log((third * 2) + tenth * 2  + (whole - ((third * 2) + tenth * 2)));
    resultsTl.to(resultsAnim, (third * 2), { opacity: 0 });
    resultsTl.to(resultsAnim, tenth, { opacity: 1 });
    resultsTl.to(resultsAnim, (whole - ((third * 2) + tenth * 2)), { opacity: 1 });
    resultsTl.to(resultsAnim, tenth, { opacity: 0 });
    // poke shadow
    pokeShadowTl.to(pss[poke5], tenth, {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke5], (third * 2 - tenth), {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke5], tenth, {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke5], (whole - ((third * 2) + tenth * 2)), {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke5], tenth, {pixi: {alpha: 0, scaleX: 1.5, scaleY: 1.5}});
    // poke reveal
    // console.log(tenth + (third * 2 - tenth) + tenth * 2  + (whole - ((third * 2) + tenth * 2)));
    pokeTl.to(ps[poke5], tenth, {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke5], (third * 2 - tenth), {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke5], tenth, {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke5], (whole - ((third * 2) + tenth * 2)), {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke5], tenth, {
      pixi: {colorize:"black", colorizeAmount: 1, alpha: 0, scaleX: 1.5, scaleY: 1.5},
      onComplete: () => {
        votes[poke5].style.opacity = 0;
        votes[poke4].style.opacity = 1;
        countdown[poke5].style.opacity = 0;
        countdown[poke4].style.opacity = 1;
        results[poke5].style.opacity = 0;
        results[poke4].style.opacity = 1;
      }
    });
    // Pokemon #5 complete


    // Pokemon #4 Start
    // header
    headerTl.to(headersAnim, whole, {opacity: 0});
    // header
    oneTl.to(one, whole, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // yellow1
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.25, scaleX: 0.25, scaleY: 0.25 } });
    // white1
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    // yellow 2
    yellow2Tl.to(yellowDisk2, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow2Tl.to(yellowDisk2, (whole - (half + tenth * 3)), { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // votes
    // console.log(fifth + tenth * 3  + (whole - (fourth + tenth * 3)));
    votesTl.to(votesAnim, fifth, { opacity: 0 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 0 });
    votesTl.to(votesAnim, (whole - (fifth + tenth * 3)), { opacity: 0 });
    // countdown
    // console.log(half + tenth * 3  + (whole - (half + tenth * 3)));
    countdownTl.to(countdownAnim, half, { opacity: 0 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 0 });
    countdownTl.to(countdownAnim, (whole - (half + tenth * 3)), { opacity: 0 });
    // results
    // console.log((third * 2) + tenth * 2  + (whole - ((third * 2) + tenth * 2)));
    resultsTl.to(resultsAnim, (third * 2), { opacity: 0 });
    resultsTl.to(resultsAnim, tenth, { opacity: 1 });
    resultsTl.to(resultsAnim, (whole - ((third * 2) + tenth * 2)), { opacity: 1 });
    resultsTl.to(resultsAnim, tenth, { opacity: 0 });
    // poke shadow
    pokeShadowTl.to(pss[poke4], tenth, {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke4], (third * 2 - tenth), {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke4], tenth, {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke4], (whole - ((third * 2) + tenth * 2)), {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke4], tenth, {pixi: {alpha: 0, scaleX: 1.5, scaleY: 1.5}});
    // poke reveal
    pokeTl.to(ps[poke4], tenth, {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke4], (third * 2 - tenth), {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke4], tenth, {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke4], (whole - ((third * 2) + tenth * 2)), {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke4], tenth, {
      pixi: {colorize:"black", colorizeAmount: 1, alpha: 0, scaleX: 1.5, scaleY: 1.5},
      onComplete: () => {
        votes[poke4].style.opacity = 0;
        votes[poke3].style.opacity = 1;
        countdown[poke4].style.opacity = 0;
        countdown[poke3].style.opacity = 1;
        results[poke4].style.opacity = 0;
        results[poke3].style.opacity = 1;
      }
    });
    // Pokemon #4 complete

    // Pokemon #3
    // header
    headerTl.to(headersAnim, whole, {opacity: 0});
    // header
    oneTl.to(one, whole, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // yellow1
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.25, scaleX: 0.25, scaleY: 0.25 } });
    // white1
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    // yellow 2
    yellow2Tl.to(yellowDisk2, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow2Tl.to(yellowDisk2, (whole - (half + tenth * 3)), { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // votes
    // console.log(fifth + tenth * 3  + (whole - (fourth + tenth * 3)));
    votesTl.to(votesAnim, fifth, { opacity: 0 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 0 });
    votesTl.to(votesAnim, (whole - (fifth + tenth * 3)), { opacity: 0 });
    // countdown
    // console.log(half + tenth * 3  + (whole - (half + tenth * 3)));
    countdownTl.to(countdownAnim, half, { opacity: 0 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 0 });
    countdownTl.to(countdownAnim, (whole - (half + tenth * 3)), { opacity: 0 });
    // results
    // console.log((third * 2) + tenth * 2  + (whole - ((third * 2) + tenth * 2)));
    resultsTl.to(resultsAnim, (third * 2), { opacity: 0 });
    resultsTl.to(resultsAnim, tenth, { opacity: 1 });
    resultsTl.to(resultsAnim, (whole - ((third * 2) + tenth * 2)), { opacity: 1 });
    resultsTl.to(resultsAnim, tenth, { opacity: 0 });
    // poke shadow
    pokeShadowTl.to(pss[poke3], tenth, {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke3], (third * 2 - tenth), {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke3], tenth, {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke3], (whole - ((third * 2) + tenth * 2)), {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke3], tenth, {pixi: {alpha: 0, scaleX: 1.5, scaleY: 1.5}});
    // poke reveal
    pokeTl.to(ps[poke3], tenth, {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke3], (third * 2 - tenth), {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke3], tenth, {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke3], (whole - ((third * 2) + tenth * 2)), {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke3], tenth, {
      pixi: {colorize:"black", colorizeAmount: 1, alpha: 0, scaleX: 1.5, scaleY: 1.5},
      onComplete: () => {
        votes[poke3].style.opacity = 0;
        votes[poke2].style.opacity = 1;
        countdown[poke3].style.opacity = 0;
        countdown[poke2].style.opacity = 1;
        results[poke3].style.opacity = 0;
        results[poke2].style.opacity = 1;
      }
    });
    // Pokemon #3 complete

    // Pokemon #2
    // header
    headerTl.to(headersAnim, whole, {opacity: 0});
    // header
    oneTl.to(one, whole, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // yellow1
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.25, scaleX: 0.25, scaleY: 0.25 } });
    // white1
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    // yellow 2
    yellow2Tl.to(yellowDisk2, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow2Tl.to(yellowDisk2, (whole - (half + tenth * 3)), { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // votes
    // console.log(fifth + tenth * 3  + (whole - (fourth + tenth * 3)));
    votesTl.to(votesAnim, fifth, { opacity: 0 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 0 });
    votesTl.to(votesAnim, (whole - (fifth + tenth * 3)), { opacity: 0 });
    // countdown
    // console.log(half + tenth * 3  + (whole - (half + tenth * 3)));
    countdownTl.to(countdownAnim, half, { opacity: 0 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 0 });
    countdownTl.to(countdownAnim, (whole - (half + tenth * 3)), { opacity: 0 });
    // results
    // console.log((third * 2) + tenth * 2  + (whole - ((third * 2) + tenth * 2)));
    resultsTl.to(resultsAnim, (third * 2), { opacity: 0 });
    resultsTl.to(resultsAnim, tenth, { opacity: 1 });
    resultsTl.to(resultsAnim, (whole - ((third * 2) + tenth * 2)), { opacity: 1 });
    resultsTl.to(resultsAnim, tenth, { opacity: 0 });
    // poke shadow
    pokeShadowTl.to(pss[poke2], tenth, {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke2], (third * 2 - tenth), {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke2], tenth, {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke2], (whole - ((third * 2) + tenth * 2)), {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke2], tenth, {pixi: {alpha: 0, scaleX: 1.5, scaleY: 1.5}});
    // poke reveal
    pokeTl.to(ps[poke2], tenth, {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke2], (third * 2 - tenth), {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke2], tenth, {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke2], (whole - ((third * 2) + tenth * 2)), {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke2], tenth, {
      pixi: {colorize:"black", colorizeAmount: 1, alpha: 0, scaleX: 1.5, scaleY: 1.5},
      onComplete: () => {
        votes[poke2].style.opacity = 0;
        votes[poke1].style.opacity = 1;
        countdown[poke2].style.opacity = 0;
        countdown[poke1].style.opacity = 1;
        results[poke2].style.opacity = 0;
        results[poke1].style.opacity = 1;
      }
    });
    // Pokemon #2 complete

    // Pokemon #1
    // header
    headerTl.to(headersAnim, whole, {opacity: 0});
    // header
    oneTl.to(one, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    oneTl.to(one, half, { pixi: { alpha: 0.2, scaleX: 1, scaleY: 1 } });
    // yellow1
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    // yellow1Tl.to(yellowDisk1, 0, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    yellow1Tl.to(yellowDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 1, scaleY: 1 } });
    // white1
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.75, scaleX: 0.5, scaleY: 0.5 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    white1Tl.to(whiteDisk1, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    // yellow 2
    yellow2Tl.to(yellowDisk2, half, { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0.5, scaleX: 0.5, scaleY: 0.5 } });
    yellow2Tl.to(yellowDisk2, tenth, { pixi: { alpha: 0, scaleX: 1, scaleY: 1 } });
    yellow2Tl.to(yellowDisk2, (whole - (half + tenth * 3)), { pixi: { alpha: 0, scaleX: 0, scaleY: 0 } });
    // votes
    // console.log(fifth + tenth * 3  + (whole - (fourth + tenth * 3)));
    votesTl.to(votesAnim, fifth, { opacity: 0 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 1 });
    votesTl.to(votesAnim, tenth, { opacity: 0 });
    votesTl.to(votesAnim, (whole - (fifth + tenth * 3)), { opacity: 0 });
    // countdown
    // console.log(half + tenth * 3  + (whole - (half + tenth * 3)));
    countdownTl.to(countdownAnim, half, { opacity: 0 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 1 });
    countdownTl.to(countdownAnim, tenth, { opacity: 0 });
    countdownTl.to(countdownAnim, (whole - (half + tenth * 3)), { opacity: 0 });
    // results
    // console.log((third * 2) + tenth  + (whole - ((third * 2) + tenth)));
    resultsTl.to(resultsAnim, (third * 2), { opacity: 0 });
    resultsTl.to(resultsAnim, tenth, { opacity: 1 });
    resultsTl.to(resultsAnim, (whole - ((third * 2) + tenth)), { opacity: 1 });
    // poke shadow
    pokeShadowTl.to(pss[poke1], tenth, {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke1], (third * 2 - tenth), {pixi: {alpha: 0.5, scaleX: 0.5, scaleY: 0.5}});
    pokeShadowTl.to(pss[poke1], tenth, {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    pokeShadowTl.to(pss[poke1], (whole - ((third * 2) + tenth)), {pixi: {alpha: 1, scaleX: 1, scaleY: 1}});
    // poke reveal
    pokeTl.to(ps[poke1], tenth, {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke1], (third * 2 - tenth), {pixi: {colorize:"black", colorizeAmount: 1, alpha: 0.25, scaleX: 0.5, scaleY: 0.5}});
    pokeTl.to(ps[poke1], tenth, {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});
    pokeTl.to(ps[poke1], (whole - ((third * 2) + tenth)), {pixi: {colorize:"black", colorizeAmount: 0, alpha: 1, scaleX: 1, scaleY: 1}});

    headerTl.pause();
    yellow1Tl.pause();
    yellow2Tl.pause();
    white1Tl.pause();
    oneTl.pause();
    votesTl.pause();
    countdownTl.pause();
    resultsTl.pause();
    pokeShadowTl.pause();
    pokeTl.pause();

    heroTl.timelineRefs = {
      headerTl,
      yellow1Tl,
      yellow2Tl,
      white1Tl,
      oneTl,
      votesTl,
      countdownTl,
      resultsTl,
      pokeShadowTl,
      pokeTl
    };
  }
  setProgress ( progress ) {
    let heroTl = this;
    let {
      headerTl,
      yellow1Tl,
      yellow2Tl,
      white1Tl,
      oneTl,
      votesTl,
      countdownTl,
      resultsTl,
      pokeShadowTl,
      pokeTl
    } = heroTl.timelineRefs;

    // timeline bridge from user interaction
    // grab reference to timelines and set their progress
    headerTl.progress( progress );
    yellow1Tl.progress( progress );
    yellow2Tl.progress( progress );
    white1Tl.progress( progress );
    oneTl.progress( progress );
    votesTl.progress( progress );
    countdownTl.progress( progress );
    resultsTl.progress( progress );
    pokeShadowTl.progress( progress );
    pokeTl.progress( progress );

  }
}

export default HeroTimeline;
