import Hero from "./hero";
import PokemonRegionSelector from "./pokemon-region-selector";
import PokemonListManager from "./pokemon-list-manager";
import POKEMON_DATA from "../data";

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  document.activeElement.blur();
}

class Home {
  constructor(props) {

    let hero = new Hero();
    let pokemonRegionSelector = new PokemonRegionSelector();
    let pokemonListManager = new PokemonListManager();

    pokemonRegionSelector.onUpdate = function ( region ) {
      if (POKEMON_DATA[region]) {
        hero.setRegion( region );
        pokemonListManager.hideFullResults();
        pokemonListManager.setRegion( region );
        scrollToTop();
      }
    };

    hero.onAllRotationsComplete = function () {
      pokemonListManager.showFullResults();
    };

    hero.onReplayButton = function () {
      pokemonListManager.hideFullResults();
    };

  }
}

export default Home;
